import React from 'react'
import PropTypes from 'prop-types'
import { Chip, Divider, Grid, Typography } from '@mui/material'

import { ExpandablePaper, GeneralPaper } from '@components/Paper'
import { Link } from '@components/Link'

import { stringifyTime } from '@lib/utils/index'

const TOCPaper = ({ toc }) => {
  return (
    <ExpandablePaper
      title={<Typography variant={'x-small-semibold'}> Table of Contents </Typography>}
      accentedHeader
      expandOnHeaderClick
    >
      {toc.map((item, index) => (
        <GeneralPaper
          key={index}
          variant={'filled'}
          sx={{ mb: 2 }}
          title={
            <>
              <Chip size={'small'} label={index + 1} sx={{ mr: 2 }} />
              <Typography variant={'x-small-semibold'}>
                {item.entry.link ? (
                  <Link to={item.entry.link} underline="hover">
                    {item.entry.title}
                  </Link>
                ) : (
                  item.entry.title
                )}
              </Typography>
            </>
          }
          subtitle={
            <Typography variant={'x-small-semibold'} sx={{ mr: 1 }}>
              {stringifyTime(item.entry)}
            </Typography>
          }
        >
          {!!item.items?.length && (
            <>
              <Divider />
              <ol>
                {item.items.map((subItem, index) => (
                  <li key={index} type={'a'} style={{ marginBottom: '16px', marginLeft: '16px' }}>
                    <Grid container spacing={2} direction="row" justifyContent="space-between" alignItems="center">
                      <Grid item>
                        <Typography variant={'x-small-regular'}>
                          {subItem.link ? (
                            <Link to={subItem.link} underline="hover">
                              {subItem.title}
                            </Link>
                          ) : (
                            subItem.title
                          )}
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography variant={'x-small-regular'} sx={{ mr: 1 }}>
                          {stringifyTime(subItem)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </li>
                ))}
              </ol>
            </>
          )}
        </GeneralPaper>
      ))}
    </ExpandablePaper>
  )
}

TOCPaper.propTypes = {
  toc: PropTypes.array,
}

TOCPaper.defaultProps = {
  toc: [],
}

export default TOCPaper
