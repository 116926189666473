import React from "react";
import PropTypes from "prop-types";
import { MenuItem } from "@mui/material";

import BasicFilter from "./helpers/basic-filter";
import { transformCollection } from "@lib/utils/transform";

const SubscriptionsFilter = props => {
  const { filterOptions, filters, setFilters } = props;
  const { title, subscriptions, multiple } = filterOptions;

  const transformedSubscriptions = transformCollection(subscriptions);

  return (
    <BasicFilter name={"subscriptions"} multiple={multiple} setFilters={setFilters} filters={filters}>
      <MenuItem value={0}>{title}</MenuItem>

      {transformedSubscriptions.map(subscription => (
        <MenuItem key={subscription.id} value={subscription.id}>
          {subscription.name}
        </MenuItem>
      ))}

    </BasicFilter>
  );
};

SubscriptionsFilter.propTypes = {
  filterOptions: PropTypes.shape({
    title: PropTypes.string,
    multiple: PropTypes.bool,

    subscriptions: PropTypes.object
  }),

  setFilters: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired
};

SubscriptionsFilter.defaultProps = {
  filterOptions: {
    subscriptions: {}
  }
};

export default SubscriptionsFilter;
