import React from "react";
import PropTypes from "prop-types";
import { MenuItem } from "@mui/material";

import BasicFilter from "./helpers/basic-filter";

import { transformCollection } from "@lib/utils/transform";

const SubjectsFilter = props => {
  const { filterOptions, filters, setFilters } = props;
  const { title, subjects, multiple } = filterOptions;

  const transformedSubjects = transformCollection(subjects);

  return (
    <BasicFilter name={"subjects"} multiple={multiple} setFilters={setFilters} filters={filters}>
      <MenuItem value={0}>{title}</MenuItem>

      {transformedSubjects.map(subject => (
        <MenuItem key={subject.id} value={subject.id}>
          {subject.name}
        </MenuItem>
      ))}

    </BasicFilter>
  );
};

SubjectsFilter.propTypes = {
  filterOptions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    multiple: PropTypes.bool.isRequired,

    subjects: PropTypes.object
  }),

  setFilters: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired
};

SubjectsFilter.defaultProps = {
  filterOptions: {
    subjects: {}
  }
};

export default SubjectsFilter;
