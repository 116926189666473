import React from "react";
import PropTypes from "prop-types";

import { ButtonGroup, Button, ListItem } from "@mui/material";

const ConfirmFilters = ({onApply, onCancel, hasChanges}) => {
  return (
    <ListItem sx={{pt: 2}}>
      <ButtonGroup fullWidth>
        <Button onClick={onCancel}>Cancel</Button>
        <Button variant={"contained"} onClick={onApply} disabled={!hasChanges}>Apply</Button>
      </ButtonGroup>
    </ListItem>
  );
};

ConfirmFilters.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onApply: PropTypes.func.isRequired,
  hasChanges: PropTypes.bool,
};

ConfirmFilters.defaultProps = {
  hasChanges: false,
};

export default ConfirmFilters;
