import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { Box, Button, Grid, Stack, Typography } from '@mui/material'

import { navigate } from 'gatsby'

import { useGetProjectQuery } from '@store/api/steamhub/project.api.graphql'

import { useNav } from '@lib/hooks/index'
import { transformCollection, transformEntity } from '@lib/utils/index'

import Gallery from '@components/Gallery'
import { RichText } from '@components/RichText'

import { LearningDurationChip, PreparationDurationChip, GradesChip } from '@steamhub/components/chips'

import { LearningGoalsPaper, MaterialsPaper, SubjectsPaper, TOCPaper, SectionsNavigatorPaper } from '@steamhub/components/papers'
import { ProjectPlanSection, ProjectDeliverySection, ProjectEvaluationSection, CommonSection } from '@steamhub/components/sections'

import { Loader } from '@components/Loader'

const Project = ({ ...props }) => {
  const { setPage } = useNav()
  const { id } = props

  const { data, isLoading, isError, refetch } = useGetProjectQuery(id)

  useEffect(() => {
    setPage('library')
  })

  let project = null
  let subjects = null

  let galleryItems = null
  let sectionStep = 0

  let hasGallery = false
  let mainGalleryImage = null
  let restGalleryImages = null

  if (data?.project?.data) {
    project = transformEntity(data.project.data)

    subjects = transformCollection(project.subjects)

    // gallery
    if (project.gallery?.main?.data) {
      hasGallery = true
      mainGalleryImage = project.gallery.main.data.attributes
      restGalleryImages = transformCollection(project.gallery.files)
      galleryItems = [mainGalleryImage, ...restGalleryImages]
    }
  }

  return (
    <>
      <Button
        onClick={() => navigate(-1)}
        size={'small'}
        variant={'text'}
        color={'secondary'}
        sx={{
          mt: -3,
          p: 0,
        }}
      >
        &lt; Go back
      </Button>

      <Grid container spacing={2}>
        {isLoading && (
          <Grid item xs={12}>
            <Loader />
          </Grid>
        )}

        {isError && (
          <Grid item xs={12}>
            Error happened
          </Grid>
        )}

        {project && (
          <>
            {/*gallery*/}
            <Grid item xs={4}>
              {hasGallery && <Gallery items={galleryItems} />}
            </Grid>

            {/*intro*/}
            <Grid item xs={8}>
              <Stack spacing={2}>
                <Box>
                  {project.preparationDuration && (
                    <PreparationDurationChip duration={project.preparationDuration} sx={{ mr: 2 }} />
                  )}

                  {project.learningDuration && (
                    <LearningDurationChip duration={project.learningDuration} sx={{ mr: 2 }} />
                  )}

                  {!!project.grades.data.length && <GradesChip grades={project.grades} sx={{ mr: 2 }} />}
                </Box>

                <Typography variant={'x-headline1-bold'}> {project.name} </Typography>

                <Box sx={{ pb: 2 }}>
                  <RichText data={project.description} />
                </Box>

                {!!project.goals.length && <LearningGoalsPaper goals={project.goals} />}

                {!!project.materials.length && <MaterialsPaper materials={project.materials} />}

                {subjects && !!subjects.length && <SubjectsPaper subjects={subjects} />}

                {!!project.tableOfContents?.length && <TOCPaper toc={project.tableOfContents} />}
              </Stack>
            </Grid>

            {/*navigation*/}
            {project.sections.length > 1 && (
              <Grid item xs={12} sx={{ mt: 4 }}>
                <SectionsNavigatorPaper sections={project.sections} />
              </Grid>
            )}

            {/*sections*/}
            <Grid item xs={12} sx={{ mt: 4 }}>
              {project.sections.map((section, index) => {
                switch (section.__typename) {
                  case 'ComponentSectionsProjectPlanSection':
                    return (
                      <ProjectPlanSection
                        key={index}
                        step={section.enumerable ? ++sectionStep : null}
                        section={section}
                      />
                    )

                  case 'ComponentSectionsProjectDeliverySection':
                    return (
                      <ProjectDeliverySection
                        key={index}
                        step={section.enumerable ? ++sectionStep : null}
                        section={section}
                      />
                    )

                  case 'ComponentSectionsProjectEvaluationSection':
                    return (
                      <ProjectEvaluationSection
                        key={index}
                        step={section.enumerable ? ++sectionStep : null}
                        section={section}
                      />
                    )

                  case 'ComponentSectionsCommonSection':
                    return (
                      <CommonSection key={index} step={section.enumerable ? ++sectionStep : null} section={section} />
                    )

                  default:
                    console.error('Unknown section type', section.__typename)
                    return <></>
                }
              })}
            </Grid>

            {/*how-to videos*/}

            {/*resources*/}
          </>
        )}
      </Grid>
    </>
  )
}

Project.propTypes = {
  id: PropTypes.string.isRequired,
}
export default Project
