import React from 'react'
import PropTypes from 'prop-types'
import { LessonCard, ProjectCard, ActivityCard, ResourceCard } from '@steamhub/components/cards'

export const AutomaticCard = ({ item, ...rest }) => {
  switch (item.__typename) {
    case 'LessonEntity':
      return <LessonCard lesson={item} {...rest} />
    case 'ProjectEntity':
      return <ProjectCard project={item} {...rest} />
    case 'ActivityEntity':
      return <ActivityCard activity={item} {...rest} />
    case 'ResourceEntity':
      return <ResourceCard resource={item} {...rest} />
    default:
      console.warn('Unknown item type', item.__typename)
      return <></>
  }
}

AutomaticCard.propTypes = {
  item: PropTypes.object,
}
