import React from 'react'
import PropTypes from 'prop-types'
import { ExpandablePaper, Paper } from '@components/Paper'
import { Typography } from '@mui/material'

const LearningGoalsPaper = ({ goals }) => {
  return (
    <ExpandablePaper
      title={<Typography variant={'x-small-semibold'}> Learning goals </Typography>}
      accentedHeader
      expandOnHeaderClick
    >
      {goals.map((goal, index) => (
        <Paper key={index} variant={'filled'} sx={{ mb: 1 }}>
          {goal.body}
        </Paper>
      ))}
    </ExpandablePaper>
  )
}

LearningGoalsPaper.propTypes = {
  goals: PropTypes.array,
}

LearningGoalsPaper.defaultProps = {
  goals: [],
}

export default LearningGoalsPaper
