import React from 'react'
import PropTypes from 'prop-types'

import GeneralSection from './general-section'

const LessonPlanSection = ({ section, step }) => {
  return (
    <GeneralSection
      section={section}
      step={step}
      defaults={{
        title: 'Teachers Guide',
      }}
    />
  )
}

LessonPlanSection.propTypes = {
  section: PropTypes.object.isRequired,
  step: PropTypes.number,
}

export default LessonPlanSection
