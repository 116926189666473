import React from 'react'
import PropTypes from 'prop-types'
import { ExpandablePaper, Paper } from '@components/Paper'
import { Typography } from '@mui/material'

const MaterialsPaper = ({ materials }) => {
  return (
    <ExpandablePaper
      title={<Typography variant={'x-small-semibold'}> Require materials </Typography>}
      accentedHeader
      expandOnHeaderClick
    >
      {materials.map((material, index) => (
        <Paper key={index} variant={'filled'} sx={{ mb: 1 }}>
          {material.body}
        </Paper>
      ))}
    </ExpandablePaper>
  )
}

MaterialsPaper.propTypes = {
  materials: PropTypes.array,
}

MaterialsPaper.defaultProps = {
  materials: [],
}

export default MaterialsPaper
