export const API_URL = process.env.GATSBY_API_URL

/**
 * take appropriate image url from the item for specified format size
 * in case size is not found - take item url
 * size can be "small", "medium", "large", "preview"
 *
 * @param {object} item
 * @param {string} size
 * @returns {string|null}
 */
export const calculateImageUrl = (item, size = 'small') => {
  if (!item) return null

  const url = item.formats?.[size]?.url || item.url

  // if url starts with http or https or "//" - return it as is
  if (url.match(/^https?:\/\//) || url.match(/^\/\//)) {
    return url
  }

  return API_URL + url
}

/**
 * Calculate endpoint url for the specified entity type
 * @returns {string|null}
 */
export const calculateUrlForEntity = ({ type, id }) => {
  switch (type) {
    case 'ProjectEntity':
      return `/projects/${id}`
    case 'LessonEntity':
      return `/lessons/${id}`
    case 'ChallengeEntity':
      return `/challenges/${id}`
    case 'ActivityEntity':
      return `/activities/${id}`
    case 'ResourceEntity':
      return `/resources/${id}`
    case 'SectionEntity':
      return `/section/${id}`
    default:
      console.error(`Unknown entity type: ${type}`)
      return null
  }
}
