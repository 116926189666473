import React from 'react'
import PropTypes from 'prop-types'
import { Button, Fab, Grid, Typography, Skeleton } from '@mui/material'

import { colors as themeColors } from '@themes/config/theme-colors'
import { Paper } from '@components/Paper'

const CallToActionPaper = ({ icon, title, buttonTitle, onClick, loading, butttonProps }) => {
  return (
    <Paper sx={{ height: '100%', width: '100%' }}>
      <Grid
        container
        spacing={2}
        alignItems="stretch"
        justifyContent="space-between"
        direction={'row'}
        sx={{ height: '100%' }}
      >
        <Grid item xs={4}>
          <Fab color="default" onClick={onClick}>
            {icon}
          </Fab>
        </Grid>

        <Grid item xs={8}>
          <Grid container direction={'column'} alignItems={'stretch'} justifyContent={'space-between'}>
            <Grid item sx={{ textAlign: 'left' }}>
              <Typography variant="x-body-bold">{loading ? <Skeleton /> : title}</Typography>
            </Grid>

            <Grid item sx={{ mt: 2, textAlign: 'left' }}>
              {loading ? (
                <Skeleton />
              ) : (
                <Button onClick={onClick} variant="contained" color="primary" {...butttonProps}>
                  {buttonTitle}
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

CallToActionPaper.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  butttonProps: PropTypes.object,
}

CallToActionPaper.defaultProps = {
  buttonTitle: 'Go',
  onClick: () => { },
  loading: false,
  butttonProps: {},
}

export default CallToActionPaper
