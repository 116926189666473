import React from 'react'
import PropTypes from 'prop-types'

import { Box, Button, Grid, Stack, Typography } from '@mui/material'
import { RichText } from '@components/RichText'
import { Link } from '@components/Link'
import { Paper, GeneralPaper } from '@components/Paper'

import { LearningDurationChip, PreparationDurationChip, SectionStepChip } from '@steamhub/components/chips'

const GeneralSection = ({ section, step, defaults }) => {
  const anchor = step ? { id: `section-${step}` } : {}

  return (
    <Paper variant={'filled'} sx={{ mb: 4 }} {...anchor}>
      <Stack spacing={2} sx={{ alignItems: 'center', pt: 3, pr: 3, pb: 3 }}>
        {!!step && <SectionStepChip step={step} />}

        <Typography variant={'x-headline2-bold'}>{section.title}</Typography>

        {!!section.description && (
          <Box sx={{ pl: 4, pr: 4, textAlign: 'center' }}>
            <RichText data={section.description} />
          </Box>
        )}

        <Grid container spacing={2}>
          {!!(
            section.captions.length ||
            section.buttons.length ||
            section.preparationDuration ||
            section.learningDuration
          ) && (
              <Grid item xs={12}>
                <GeneralPaper
                  title={
                    section.captions.length ? (
                      section.captions.map((caption, key) => {
                        return (
                          <Typography variant={'x-small-bold'} key={key} sx={{ mr: 1 }}>
                            {caption.link ? (
                              <Link to={caption.link} underline={'hover'}>
                                {caption.title}
                              </Link>
                            ) : (
                              caption.title
                            )}
                          </Typography>
                        )
                      })
                    ) : (
                      <Typography variant={'x-small-bold'}>{defaults.title}</Typography>
                    )
                  }
                  subtitle={
                    <>
                      {!!section.preparationDuration && (
                        <PreparationDurationChip duration={section.preparationDuration} sx={{ mr: 2 }} />
                      )}

                      {!!section.learningDuration && (
                        <LearningDurationChip duration={section.learningDuration} sx={{ mr: 2 }} />
                      )}
                    </>
                  }
                  actions={
                    section.buttons.length
                      ? section.buttons.map((button, key) => {
                        return (
                          <Button
                            variant="contained"
                            color="tertiary"
                            key={key}
                            sx={{ ml: 2 }}
                            href={button.link}
                            target={'_blank'}
                          >
                            {button.title}
                          </Button>
                        )
                      })
                      : null
                  }
                />
              </Grid>
            )}
          {!!section.content && (
            <Grid item xs={12}>
              <Paper>
                <RichText data={section.content} />
              </Paper>
            </Grid>
          )}
        </Grid>
      </Stack>
    </Paper>
  )
}

GeneralSection.propTypes = {
  section: PropTypes.object.isRequired,
  step: PropTypes.number,
  defaults: PropTypes.object,
}

GeneralSection.defaultProps = {
  defaults: {
    title: '',
  },
}

export default GeneralSection
