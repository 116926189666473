import React from 'react'
import PropTypes from 'prop-types'

import { stringifyTime } from '@lib/utils/stringify-time'
import { PreparationIcon } from '@components/icons'

import { GeneralChip } from './general-chip'

export const PreparationDurationChip = ({ duration, sx, ...rest }) => {
  return <GeneralChip label={stringifyTime(duration, 'Prep')} icon={<PreparationIcon />} sx={sx} {...rest} />
}

PreparationDurationChip.propTypes = {
  duration: PropTypes.object.isRequired,
  sx: PropTypes.object,
}

PreparationDurationChip.defaultProps = {
  sx: {},
}
