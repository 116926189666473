import React from "react";
import PropTypes from "prop-types";
import { MenuItem } from "@mui/material";

import { transformCollection } from "@lib/utils/transform";

import BasicFilter from "./helpers/basic-filter";

const ComplexityFilter = props => {
  const { filterOptions, filters, setFilters } = props;
  const { title, complexities, multiple } = filterOptions;

  const transformedComplexities = transformCollection(complexities);

  return (
    <BasicFilter name={"complexity"} multiple={multiple} setFilters={setFilters} filters={filters}>

      <MenuItem value={0}>{title}</MenuItem>

      {transformedComplexities.map(complexity => (
        <MenuItem key={complexity.id} value={complexity.id}>
          {complexity.name}
        </MenuItem>
      ))}
    </BasicFilter>
  );
};

ComplexityFilter.propTypes = {
  filterOptions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    multiple: PropTypes.bool.isRequired,

    complexities: PropTypes.object
  }),

  setFilters: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired
};

ComplexityFilter.defaultProps = {
  filterOptions: {
    complexities: {}
  }
};

export default ComplexityFilter;
