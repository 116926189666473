import React from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'

import { Box, Button, Grid, Typography } from '@mui/material'

import { calculateUrlForEntity, calculateImageUrl } from '@lib/utils/urls'
import Card from '@components/Card/card'

import { LearningDurationChip, PreparationDurationChip } from '@steamhub/components/chips'
import { transformCollection } from '@lib/utils/transform'
import { joinObjects } from '@lib/utils/join-objects'

export const ActivityCard = ({ activity, titleOnTop = false, ...rest }) => {
  const { preparationDuration, learningDuration, subjects } = activity
  const transformedSubjects = transformCollection(subjects)

  const previewImage = activity.preview.data
  let media = null

  if (previewImage) {
    media = calculateImageUrl(previewImage.attributes, 'small')
  }

  const targetUrl = calculateUrlForEntity({ type: 'ActivityEntity', id: activity.id })

  const onClick = () => {
    navigate(targetUrl)
  }

  const preparationDurationChip = preparationDuration ? (
    <PreparationDurationChip duration={preparationDuration} />
  ) : null

  const learningDurationChip = learningDuration ? <LearningDurationChip duration={learningDuration} /> : null

  return (
    <Card
      media={media || ''}
      mediaStyle={media ? 'overflow' : 'none'}
      onClick={onClick}
      corners={{
        lb: preparationDurationChip,
        rb: learningDurationChip,
      }}
      header={
        media && titleOnTop ? (
          <Box sx={{ alignItems: 'center', mb: 2 }}>
            <Typography variant={'x-body-bold'} color={'grey.700'}>
              {activity.name}
            </Typography>
          </Box>
        ) : null
      }
      footer={
        <Box sx={{ textAlign: 'center', mt: 1 }} title={'Subjects'}>
          <Typography variant={'x-xsmall-bold'} color={'grey.400'}>
            {joinObjects(transformedSubjects, 'code', ' | ')}
          </Typography>
        </Box>
      }
      {...rest}
    >
      <Grid container alignItems={'center'} direction={'column'} spacing={2} sx={{ marginTop: 0, height: '100%' }}>
        {!(media && titleOnTop) && (
          <Grid item sx={{ textAlign: 'center' }}>
            <Typography variant={'x-body-bold'} color={'grey.700'}>
              {activity.name}
            </Typography>
          </Grid>
        )}

        <Grid item>
          <Button variant={'contained'} color={'tertiary'} size={'small'} onClick={onClick}>
            Run Activity
          </Button>
        </Grid>
      </Grid>
    </Card>
  )
}

ActivityCard.propTypes = {
  activity: PropTypes.object,
}
