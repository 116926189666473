import React from 'react'
import SimpleDialog, { propTypes } from '@components/Dialog/simple-dialog'
import { Grid, TextField } from '@mui/material'

const ChangeEmailDialog = props => {
  return (
    <SimpleDialog title={'Change Password'} {...props}>
      <Grid container spacing={2} columns={3}>
        <Grid item xs={3}>
          <TextField
            name={'current-password'}
            label={'Current password'}
            type={'password'}
            variant={'filled'}
            size={'large'}
            fullWidth
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            name={'new-password'}
            label="New password"
            type="password"
            variant={'filled'}
            size={'large'}
            fullWidth
            helperText={'Password must be at least 8 characters long'}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            name={'new-password-confirmation'}
            label="Confirm new password"
            type="password"
            variant={'filled'}
            size={'large'}
            fullWidth
          />
        </Grid>
      </Grid>
    </SimpleDialog>
  )
}

ChangeEmailDialog.propTypes = propTypes

export default ChangeEmailDialog
