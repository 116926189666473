import React from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { Box, Button, Grid, Typography } from '@mui/material'

import { joinObjects, transformCollection, calculateUrlForEntity, calculateImageUrl } from '@lib/utils/index'
import Card from '@components/Card/card'

import { LearningDurationChip, PreparationDurationChip } from '@steamhub/components/chips'

export const ProjectCard = ({ project, titleOnTop = false, ...rest }) => {
  const { preparationDuration, learningDuration, subjects } = project
  const transformedSubjects = transformCollection(subjects)

  const previewImage = project.preview.data
  let media = null

  if (previewImage) {
    media = calculateImageUrl(previewImage.attributes, 'small')
  }

  const targetUrl = calculateUrlForEntity({ type: 'ProjectEntity', id: project.id })

  const onClick = () => {
    navigate(targetUrl)
  }

  const preparationDurationChip = preparationDuration ? (
    <PreparationDurationChip duration={preparationDuration} />
  ) : null

  const learningDurationChip = learningDuration ? <LearningDurationChip duration={learningDuration} /> : null

  return (
    <Card
      media={media || ''}
      mediaStyle={media ? 'overflow' : 'none'}
      onClick={onClick}
      corners={{
        lb: preparationDurationChip,
        rb: learningDurationChip,
      }}
      header={
        media && titleOnTop ? (
          <Box sx={{ alignItems: 'center', mb: 2 }}>
            <Typography variant={'x-body-bold'} color={'grey.700'}>
              {project.name}
            </Typography>
          </Box>
        ) : null
      }
      footer={
        <Box sx={{ textAlign: 'center', mt: 1 }} title={'Subjects'}>
          <Typography variant={'x-xsmall-bold'} color={'grey.400'}>
            {joinObjects(transformedSubjects, 'code', ' | ')}
          </Typography>
        </Box>
      }
      {...rest}
    >
      <Grid container alignItems={'center'} direction={'column'} spacing={2} sx={{ marginTop: 0, height: '100%' }}>
        {!(media && titleOnTop) && (
          <Grid item sx={{ textAlign: 'center' }}>
            <Typography variant={'x-body-bold'} color={'grey.700'}>
              {project.name}
            </Typography>
          </Grid>
        )}

        <Grid item>
          <Button variant={'contained'} color={'tertiary'} size={'small'} onClick={onClick}>
            Get Started
          </Button>
        </Grid>
      </Grid>
    </Card>
  )
}

ProjectCard.propTypes = {
  project: PropTypes.object,
}
