import React from "react";
import PropTypes from "prop-types";

import { MenuItem } from "@mui/material";

import BasicFilter from "./helpers/basic-filter";

const ContentTypeFilter = props => {
  const { filterOptions, filters, setFilters } = props;
  const { title, multiple, showActivities, showChallenges, showLessons, showProjects, showResources } = filterOptions;

  return (
    <BasicFilter name={"contentType"} multiple={multiple} setFilters={setFilters} filters={filters}>
      <MenuItem value={0}>{title}</MenuItem>

      {showActivities && <MenuItem value="activity">Activity</MenuItem>}
      {showChallenges && <MenuItem value="challenge">Challenge</MenuItem>}
      {showLessons && <MenuItem value="lesson">Lesson</MenuItem>}
      {showProjects && <MenuItem value="project">Project</MenuItem>}
      {showResources && <MenuItem value="resource">Resource</MenuItem>}
    </BasicFilter>
  );
};

ContentTypeFilter.propTypes = {
  filterOptions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    multiple: PropTypes.bool.isRequired,

    showActivities: PropTypes.bool,
    showChallenges: PropTypes.bool,
    showLessons: PropTypes.bool,
    showProjects: PropTypes.bool,
    showResources: PropTypes.bool
  }),

  setFilters: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired
};

ContentTypeFilter.defaultProps = {
  filterOptions: {
    showActivities: false,
    showChallenges: false,
    showLessons: false,
    showProjects: false,
    showResources: false
  }
};

export default ContentTypeFilter;
