export const processFilter = (value = []) => {
  // if value is string, then return it
  if (typeof value === 'string') {
    return value
  }

  if(!value.length) {
    return null
  }

  // if first element of value array is 0, then filter it out and return it
  if (value.length > 1 && value[0] === 0) {
    value.shift()
    return value
  }

  // if last element of value array is 0, then filter all elements except 0 and return it
  if (value[value.length - 1] === 0) {
    return null
  }

  return value
}
