import React from 'react'
import PropTypes from 'prop-types'

import parse from 'html-react-parser'

import { Box } from '@mui/material'

const Raw = props => {
  const { html, ...rest } = props

  console.log('HTML', html)

  return <Box {...rest}>{parse(html)}</Box>
}

Raw.propTypes = {
  html: PropTypes.string,
}

Raw.defaultProps = {
  html: '',
}

export default Raw
