import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Select } from "@mui/material";

import { processFilter } from "./process-filter";
import ConfirmFilters from "./confirm-filters";

const BasicFilter = props => {
  const { children, name, multiple, setFilters, filters } = props;

  let initialFilters = filters[name] ? filters[name] : [0];
  
  // if initialFilters not array, but filter is multiple, convert it to an array
  if (!Array.isArray(initialFilters) && multiple) {
    initialFilters = [initialFilters];
  }

  const [currentFilters, setCurrentFilters] = React.useState(initialFilters);
  const [open, setOpen] = React.useState(false);
  const [hasChanges, setHasChanges] = React.useState(false);

  useEffect(() => {
    setCurrentFilters(initialFilters);
  }, [filters]);

  useEffect(() => {
    if (!multiple) {
      return;
    }
    // compare initialFilters against currentFilters and their contents
    // if they are different, set hasChanges to true
    // if they are the same, set hasChanges to false
    let initial = initialFilters ? initialFilters : [0];
    let current = currentFilters ? currentFilters : [0];

    if (initial === current) {
      return setHasChanges(false);
    }

    initial = ("" + initial).split(",").sort().join(",");
    current = ("" + current).split(",").sort().join(",");

    return setHasChanges(initial !== current);
  }, [currentFilters, initialFilters]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = event => {
    setOpen(false);
    setHasChanges(false);

    if (multiple && (event?.type === "click" || event?.type === "keydown")) {
      setCurrentFilters(initialFilters);
    }
  };

  const handleChange = event => {
    let { value } = event.target;
    value = processFilter(value);

    setCurrentFilters(value);

    if (!multiple) {
      return setFilters({ [name]: value });
    }
  };

  const handleApply = () => {
    setFilters({ [name]: currentFilters });
    handleClose();
  };

  const handleCancel = () => {
    setCurrentFilters(initialFilters);
    handleClose();
  };

  return (
    <Select
      variant={"filled"}
      color={"secondary"}
      fullWidth

      open={open}
      multiple={multiple}

      onChange={handleChange}
      onOpen={handleOpen}
      onClose={handleClose}

      value={currentFilters || [0]}
    >
      {children}

      {multiple && <ConfirmFilters onApply={handleApply} onCancel={handleCancel} hasChanges={hasChanges} />}
    </Select>
  );
};

BasicFilter.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string.isRequired,
  multiple: PropTypes.bool,

  setFilters: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired
};


BasicFilter.defaultProps = {
  multiple: false,
};

export default BasicFilter;
