import React from "react";
import PropTypes from "prop-types";
import { MenuItem } from "@mui/material";

import BasicFilter from "./helpers/basic-filter";

const DurationFilter = props => {
  const { filterOptions, filters, setFilters } = props;
  const { title, durations, multiple } = filterOptions;

  return (
    <BasicFilter name={"duration"} multiple={multiple} setFilters={setFilters} filters={filters}>

      <MenuItem value={0}>{title}</MenuItem>

      {durations.map(duration => (
        <MenuItem key={duration.id} value={duration.id}>
          {duration.title}
        </MenuItem>
      ))}
    </BasicFilter>

  )
}

DurationFilter.propTypes = {
  filterOptions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    multiple: PropTypes.bool.isRequired,

    durations: PropTypes.array
  }),

  setFilters: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired
}

DurationFilter.defaultProps = {
  filterOptions: {
    durations: [],
  },
}

export default DurationFilter
