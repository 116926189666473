import React from "react";
import PropTypes from "prop-types";
import { MenuItem } from "@mui/material";

import BasicFilter from "./helpers/basic-filter";
import { transformCollection } from "@lib/utils/transform";

const TagsFilter = props => {
  const { filterOptions, filters, setFilters } = props;
  const { title, tags, multiple } = filterOptions;

  const transformedTags = transformCollection(tags);

  return (
    <BasicFilter name={"tags"} multiple={multiple} setFilters={setFilters} filters={filters}>
      <MenuItem value={0}>{title}</MenuItem>

      {transformedTags.map(tag => (
        <MenuItem key={tag.id} value={tag.id}>
          {tag.name}
        </MenuItem>
      ))}

    </BasicFilter>
  )
}

TagsFilter.propTypes = {
  filterOptions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    multiple: PropTypes.bool.isRequired,

    tags: PropTypes.object
  }),

  setFilters: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired
}

TagsFilter.defaultProps = {
  filterOptions: {
    tags: {},
  },
}

export default TagsFilter
