import React, { useEffect } from 'react'

import { Container, Divider, Grid } from '@mui/material'

import SchoolSection from '@steamhub/pages/profile/school-section'
import AccountSection from '@steamhub/pages/profile/account-section'
import LogoutSection from '@steamhub/pages/profile/logout-section'
import ClassroomsSection from '@steamhub/pages/profile/classrooms-section'

import { useNav } from '@lib/hooks/index'

const Profile = ({ ...props }) => {
  const { setPage } = useNav()

  useEffect(() => {
    setPage('profile')
  })

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AccountSection />
        </Grid>

        {/*    <Grid item xs={12}  sx={{mt: 4}}>
        <SchoolSection />
      </Grid>*/}

        <Grid item xs={12} sx={{ mt: 4 }}>
          <ClassroomsSection />
        </Grid>

        <Grid item xs={12} sx={{ mt: 6 }}>
          <LogoutSection />
        </Grid>
      </Grid>
    </Container>
  )
}

export default Profile
