import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Grid, Paper as MuiPaper } from '@mui/material'

import { PaperHeader, PaperTitle, PaperSubtitle, PaperActions, PaperContent, PaperToggleAction } from './index'

const GeneralPaper = props => {
  const {
    actions,
    accentedHeader,
    children,
    content,
    expandable,
    expandOnHeaderClick,
    open,
    sx,
    subtitle,
    title,
    variant,
    ...rest
  } = props

  const [isOpen, setIsOpen] = useState(open)

  return (
    <MuiPaper
      sx={{
        pt: 2,
        pb: 2,
        pl: 2,
        pr: 2,
        boxShadow: 2,
        ...sx,
      }}
      variant={variant}
      {...rest}
    >
      <Grid container spacing={2}>
        {(title || subtitle || actions || expandable) && (
          <PaperHeader
            sx={expandable && expandOnHeaderClick ? { cursor: 'pointer' } : {}}
            accented={accentedHeader}
            open={isOpen}
            onClick={() => {
              expandable && expandOnHeaderClick && setIsOpen(!isOpen)
            }}
          >
            {title && <PaperTitle>{title}</PaperTitle>}

            {subtitle && <PaperSubtitle>{subtitle}</PaperSubtitle>}

            {(actions || expandable) && (
              <PaperActions>
                {actions}

                {expandable && (
                  <PaperToggleAction
                    open={isOpen}
                    onClick={() => {
                      setIsOpen(!isOpen)
                    }}
                  />
                )}
              </PaperActions>
            )}
          </PaperHeader>
        )}

        {content && isOpen && <PaperContent sx={accentedHeader ? { mt: 2 } : {}}>{content}</PaperContent>}

        {children && isOpen && <PaperContent sx={accentedHeader ? { mt: 2 } : {}}>{children}</PaperContent>}
      </Grid>
    </MuiPaper>
  )
}

GeneralPaper.propTypes = {
  actions: PropTypes.node,
  accentedHeader: PropTypes.bool,
  children: PropTypes.node,
  content: PropTypes.node,
  expandable: PropTypes.bool,
  expandOnHeaderClick: PropTypes.bool,
  open: PropTypes.bool,
  sx: PropTypes.object,
  subtitle: PropTypes.node,
  title: PropTypes.node,
  variant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
}

GeneralPaper.defaultProps = {
  accentedHeader: false,
  expandable: false,
  expandOnHeaderClick: false,
  open: true,
  sx: {},
  variant: 'standard',
}

export default GeneralPaper
