import React from 'react'
import PropTypes from 'prop-types'
import { ExpandablePaper, Paper } from '@components/Paper'
import { Typography } from '@mui/material'

const SubjectsPaper = ({ subjects }) => {
  return (
    <ExpandablePaper
      title={<Typography variant={'x-small-semibold'}> Core subjects </Typography>}
      accentedHeader
      expandOnHeaderClick
    >
      {subjects.map((subject, index) => (
        <Paper key={index} variant={'filled'} sx={{ mb: 1 }}>
          {subject.name}
        </Paper>
      ))}
    </ExpandablePaper>
  )
}

SubjectsPaper.propTypes = {
  subjects: PropTypes.array,
}

SubjectsPaper.defaultProps = {
  subjects: [],
}

export default SubjectsPaper
