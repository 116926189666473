import React from 'react'
import SimpleDialog, { propTypes } from '@components/Dialog/simple-dialog'

const LogoutDialog = props => {
  return <SimpleDialog {...props}>Do you really want to logout?</SimpleDialog>
}

LogoutDialog.propTypes = propTypes

export default LogoutDialog
