import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { Box, Button, Grid, Stack, Typography } from '@mui/material'

import { navigate } from 'gatsby'

import { useGetResourceQuery } from '@store/api/steamhub/resource.api.graphql'

import { useNav } from '@lib/hooks/index'

import { transformCollection, transformEntity } from '@lib/utils/index'

import Gallery from '@components/Gallery'
import { RichText } from '@components/RichText'

import { TOCPaper, SectionsNavigatorPaper, SubjectsPaper } from '@steamhub/components/papers'

import { CommonSection } from '@steamhub/components/sections'
import { Loader } from '@components/Loader'

const Resource = ({ ...props }) => {
  const { setPage } = useNav()
  const { id } = props

  const { data, isLoading, isError, refetch } = useGetResourceQuery(id)

  useEffect(() => {
    setPage('library')
  })

  let resource = null

  let subjects = null

  let galleryItems = null
  let sectionStep = 0

  let hasGallery = false
  let mainGalleryImage = null
  let restGalleryImages = null

  if (data?.resource?.data) {
    resource = transformEntity(data.resource.data)

    // gallery
    if (resource.gallery?.main?.data) {
      hasGallery = true
      mainGalleryImage = resource.gallery.main.data.attributes
      restGalleryImages = transformCollection(resource.gallery.files)
      galleryItems = [mainGalleryImage, ...restGalleryImages]
    }

    if (resource.subjects.data?.length) {
      subjects = transformCollection(resource.subjects)
    }
  }

  return (
    <>
      <Button
        onClick={() => navigate(-1)}
        size={'small'}
        variant={'text'}
        color={'secondary'}
        sx={{
          mt: -3,
          p: 0,
        }}
      >
        &lt; Go back
      </Button>

      <Grid container spacing={2}>
        {isLoading && (
          <Grid item xs={12}>
            <Loader />
          </Grid>
        )}

        {isError && (
          <Grid item xs={12}>
            Error happened
          </Grid>
        )}

        {resource && (
          <>
            <Grid item xs={4}>
              {hasGallery && <Gallery items={galleryItems} />}
            </Grid>

            <Grid item xs={8}>
              <Stack spacing={2}>
                <Typography variant={'x-headline1-bold'}> {resource.name} </Typography>

                <Box sx={{ pb: 2 }}>
                  <RichText data={resource.description} />
                </Box>

                {subjects && !!subjects.length && <SubjectsPaper subjects={subjects} />}

                {!!resource.tableOfContents?.length && <TOCPaper toc={resource.tableOfContents} />}
              </Stack>
            </Grid>

            <Grid item xs={12}></Grid>

            {/*navigation*/}
            {resource.sections.length > 1 && (
              <Grid item xs={12} sx={{ mt: 4 }}>
                <SectionsNavigatorPaper sections={resource.sections} />
              </Grid>
            )}

            {/*sections*/}
            <Grid item xs={12} sx={{ mt: 4 }}>
              {resource.sections.map((section, index) => {
                switch (section.__typename) {
                  case 'ComponentSectionsCommonSection':
                    return (
                      <CommonSection key={index} step={section.enumerable ? ++sectionStep : null} section={section} />
                    )

                  default:
                    console.error('Unknown section type', section.__typename)
                    return <></>
                }
              })}
            </Grid>

            {/*how-to videos*/}

            {/*resources*/}
          </>
        )}
      </Grid>
    </>
  )
}

Resource.propTypes = {
  id: PropTypes.string.isRequired,
}

export default Resource
