import React from "react";
import PropTypes from "prop-types";
import { MenuItem } from "@mui/material";

import BasicFilter from "./helpers/basic-filter";

const FavoriteFilter = props => {
  const { filterOptions, filters, setFilters } = props;
  const { title, multiple } = filterOptions;

  return (
    <BasicFilter name={"favorite"} multiple={multiple} setFilters={setFilters} filters={filters}>
      <MenuItem value={0}>{title}</MenuItem>

      <MenuItem value="favorite">Show my Favorites</MenuItem>
    </BasicFilter>
  );
};

FavoriteFilter.propTypes = {
  filterOptions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    multiple: PropTypes.bool.isRequired
  }),

  setFilters: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired
};

FavoriteFilter.defaultProps = {};

export default FavoriteFilter;
