import React from 'react'
import PropTypes from 'prop-types'

import { Box, Grid, Paper } from '@mui/material'

const PaperHeader = props => {
  const { children, accented, ...rest } = props
  const content = (
    <Grid item xs={12} container direction="row" justifyContent="space-between" alignItems="center" {...rest}>
      {children}
    </Grid>
  )

  if (accented) {
    return (
      <Box
        sx={{
          ml: -1,
          mr: -2,
          mb: -2,
          display: 'flex',
          flexGrow: 1,
        }}
      >
        <Paper
          sx={{
            pt: 2,
            pb: 2,
            pl: 3,
            pr: 2,
            width: '100%',
          }}
        >
          {content}
        </Paper>
      </Box>
    )
  } else {
    return content
  }
}

PaperHeader.propTypes = {
  accented: PropTypes.bool,
  children: PropTypes.node,
}

PaperHeader.defaultProps = {
  accented: false,
}

export default PaperHeader
