export const stringifyTime = (duration, suffix = '') => {
  let stringified = ''

  if (duration) {
    const hours = duration.hours || 0
    const minutes = duration.minutes || 0

    if (hours) {
      stringified = `${hours}:${normalizeMinutes(minutes)}`
    } else {
      stringified = `${normalizeMinutes(minutes)} mins`
    }
  }

  return stringified ? `${stringified} ${suffix}` : ''
}

const normalizeMinutes = minutes => {
  if (minutes < 10) {
    return `0${minutes}`
  }
  return minutes
}
