import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import { Container, Grid, Button, Typography, TextField } from '@mui/material'
import { StaticImage } from 'gatsby-plugin-image'

import { useGetSectionQuery } from '@store/api/steamhub/section.api.graphql'
import { Loader } from "@components/Loader";
import { useNav } from '@lib/hooks/index'

import {
  transformCollection,
} from '@lib/utils/index'

const Section = ({ ...props }) => {
  const { setPage } = useNav()
  const { id } = props

  const { data, isLoading, isError, refetch } = useGetSectionQuery(id)

  let section;
  let isExternal;
  let students = [];

  if (data) {
    const sections = transformCollection(data.sections)
    section = sections[0]
    isExternal = section.externalId
    students = transformCollection(section.students)
    console.log('Loaded Section', section)
  }

  useEffect(() => {
    setPage('profile')
  })

  return (
    <Container>
      <Grid container spacing={2}>

        <Grid item xs={12}>
          <Button
            onClick={() => navigate(-1)}
            size={'small'}
            variant={'text'}
            color={'secondary'}
            sx={{
              mt: -3,
              p: 0,
            }}
          >
            &lt; Go back
          </Button>
        </Grid>

        {isLoading && (
          <Grid item xs={12}>
            <Loader />
          </Grid>
        )}

        {section && <>
          <Grid item xs={12}>
            <Typography variant={'x-headline2-bold'}>{section.name}</Typography>
            {isExternal && <StaticImage src="../assets/images/clever.png" height={24} style={{ marginTop: '14px' }} alt={'Clever'} />}
          </Grid>

          <Grid item xs={12}>
            <Typography variant={'x-headline4-bold'}>Students</Typography>
          </Grid>

          {isExternal && <Grid item xs={12} container spacing={2}>
            {students.map((student) => (
              <React.Fragment key={student.id}>
                <Grid item xs={6}>
                  <TextField variant='filled' size='large' value={student.name} fullWidth aria-readonly />
                </Grid>
                <Grid item xs={6}>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>}

          {!isExternal && <Grid item xs={12}>
            Internal students
          </Grid>}
        </>
        }
      </Grid>
    </Container>
  )
}

export default Section
