import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import { Image } from 'mui-image'

import { calculateImageUrl } from '@lib/utils/index'

const Gallery = ({ items, mainProps={}, previewsProps={} }) => {
  const [activeIndex, setActiveIndex] = React.useState(0)

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        sx={{
          height: '460px',
        }}

        {...mainProps}
      >

        {items[activeIndex].mime.indexOf('image') !== -1 && (
          <Image
            src={calculateImageUrl(items[activeIndex], 'medium')}
            alt={'Main image'}
            duration={500}
            showLoading={true}
            style={{
              borderRadius: '20px',
              maxWidth: '100%',
              maxHeight: '100%',
              width: 'auto',
              height: 'auto',
            }}
          />
        )}

        {items[activeIndex].mime.indexOf('video') !== -1 && (
          <video
            src={calculateImageUrl(items[activeIndex], 'medium')}
            style={{ borderRadius: '20px' }}
            width={'100%'}
            playsInline={true}
            controls={true}
          />
        )}
      </Grid>

      {items?.length > 1 && (
        <Grid item xs={12} container spacing={1} justifyContent="start" alignItems="flex-start" {...previewsProps}>
          {items.map((item, index) => (
            <Grid item xs={3} key={index} sx={{ cursor: 'pointer' }} onClick={() => setActiveIndex(index)}>
              {item.mime.indexOf('image') > -1 && (
                <Image
                  src={calculateImageUrl(item, 'thumbnail')}
                  alt={item.title}
                  showLoading={true}
                  duration={1000}
                  height={95}
                  fit={'contain'}
                  style={{
                    borderRadius: '20px',
                    backgroundColor: '#a8a8a833',
                  }}
                />
              )}

              {item.mime.indexOf('video') > -1 && (
                <video src={calculateImageUrl(item, 'thumbnail')} width={127} style={{ borderRadius: '20px' }} />
              )}
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  )
}

Gallery.propTypes = {
  items: PropTypes.array.isRequired,
  mainProps: PropTypes.object,
  previewsProps: PropTypes.object,
}

Gallery.defaultProps = {
  items: [],
  mainProps: {},
  previewsProps: {},
}

export default Gallery
