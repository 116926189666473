import React, { useEffect } from 'react'

import { navigate } from 'gatsby'


const Library = ({ ...props }) => {
  // redirect to /r/library
  useEffect(() => {
    navigate('/r/library')
  }, []);
}

export default Library
