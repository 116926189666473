import React from 'react'
import PropTypes from 'prop-types'

import { Chip } from '@mui/material'

export const SectionStepChip = ({ step, sx, ...rest }) => {
  return (
    <Chip
      label={`STEP ${step}`}
      variant="filled"
      size={'medium'}
      color={'secondary'}
      sx={{
        color: 'grey.700',
        backgroundColor: 'rgba(39, 39, 39, 0.1)',
        borderRadius: '5px',
        typography: 'x-small-bold',
        pl: 2,
        pr: 2,
        ...sx,
      }}
      {...rest}
    />
  )
}

SectionStepChip.propTypes = {
  step: PropTypes.number.isRequired,
  sx: PropTypes.object,
}

SectionStepChip.defaultProps = {
  sx: {},
}
