import React from 'react'
import PropTypes from 'prop-types'

import { Box, Grid, Typography, Skeleton } from '@mui/material'

import { colors as themeColors } from '@themes/config/theme-colors'
import { CallToActionPaper } from '@steamhub/components/papers'
import { CommunityIcon, HelpIcon, LibraryIcon, PDIcon } from '@components/icons'
import { Paper } from '@components/Paper'
import { RichText } from '@components/RichText'

import { navigate } from '@lib/utils/navigate'

export const GreetingSection = ({ data, loading, ...rest }) => {
  const iconsStyle = {
    sx: {
      color: themeColors.violet['900']
    }
  }

  return (
    <Paper variant={'filled'} sx={{ ml: 9, mr: 9, pt: 8, textAlign: 'center', background: themeColors.secondary.blocklyApp }}>
      <Grid container spacing={2} direction={'column'} justifyContent={'center'}>
        <Grid item>
          <Typography variant={'x-headline1-semibold'}>{loading ? <Skeleton /> : data.title}</Typography>
        </Grid>

        <Grid item>
          <Typography variant={'x-body-regular'}>
            {loading ? <Skeleton /> : <RichText data={data.content} />}
          </Typography>
        </Grid>

        <Grid item>
          <Box sx={{ mt: 2, ml: -9, mr: -9 }}>
            <Grid container spacing={2} justifyContent={'stretch'}>
              <Grid item xs={12} md={6} lg={3}>
                <CallToActionPaper
                  title={data.libraryAction?.title || ''}
                  buttonTitle={data.libraryAction?.actionTitle || ''}
                  icon={<LibraryIcon {...iconsStyle} />}
                  loading={loading}
                  onClick={() => {
                    navigate(data.libraryAction?.action || '')
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={3}>
                <CallToActionPaper
                  title={data.communityAction?.title || ''}
                  buttonTitle={data.communityAction?.actionTitle || ''}
                  icon={<CommunityIcon {...iconsStyle} />}
                  loading={loading}
                  onClick={() => {
                    navigate(data.communityAction?.action || '')
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={3}>
                <CallToActionPaper
                  title={data.pdAction?.title || ''}
                  buttonTitle={data.pdAction?.actionTitle || ''}
                  icon={<PDIcon {...iconsStyle} />}
                  loading={loading}
                  onClick={() => {
                    navigate(data.pdAction?.action || '')
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={3}>
                <CallToActionPaper
                  title={data.helpAction?.title || ''}
                  buttonTitle={data.helpAction?.actionTitle || ''}
                  icon={<HelpIcon {...iconsStyle} />}
                  loading={loading}
                  onClick={() => {
                    navigate(data.helpAction?.action || '')
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  )
}

GreetingSection.propTypes = {
  data: PropTypes.object,
}
