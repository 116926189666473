import React, { useState } from 'react'
import { Button, Grid, Typography, Paper } from '@mui/material'
import { navigate } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import { useGetSectionsQuery } from '@store/api/steamhub/section.api.graphql'
import { useAuth } from "@lib/auth";
import { LibraryIcon, RemoveIcon } from "@components/icons"
import { CallToActionPaper } from "@steamhub/components/papers"

import SyncWithLMSDialog from '@steamhub/components/dialogs/sync-with-lms-dialog'
import EditSectionDialog from '@steamhub/components/dialogs/edit-section-dialog'

import {
  transformCollection,
  calculateUrlForEntity
} from '@lib/utils/index'

// todo: Move to some dedicated service
const cleverClientId = process.env.GATSBY_CLEVER_CLIENT_ID;
const cleverRedirectUri = process.env.GATSBY_CLEVER_REDIRECT_SYNC_URI;
const cleverUrl = `https://clever.com/oauth/authorize?response_type=code&client_id=${cleverClientId}&redirect_uri=${cleverRedirectUri}`;

const ClassroomSection = () => {
  const { user } = useAuth()
  const { data, isLoading, isError, refetch } = useGetSectionsQuery()

  const [isSyncWithLMSDialogOpened, setIsSyncWithLMSDialogOpened] = useState(false)
  const [isCreateSectionDialogOpened, setIsCreateSectionDialogOpened] = useState(false)

  let sections = [];
  if (data) {
    sections = transformCollection(data.sections)
  }

  return (
    <>
      <Grid container spacing={2}>

        <Grid item xs={12}>
          <Typography variant={'x-headline2-bold'}>Classroom</Typography>
        </Grid>

        <Grid item xs={12}>
          <Paper variant={"filled"} sx={{ p: 8 }}>

            <Grid container spacing={2} alignItems={'center'}>

              <Grid item xs={4}>
                <CallToActionPaper
                  title={'Import from Clever'}
                  buttonTitle={'Import'}
                  icon={<LibraryIcon />}
                  onClick={() => {
                    setIsSyncWithLMSDialogOpened(true)
                  }}
                />
              </Grid>

              <Grid item xs={4}>
                <CallToActionPaper
                  title={'Upload a .csv file'}
                  buttonTitle={'Upload'}
                  icon={<LibraryIcon />}
                  butttonProps={{ disabled: true }}
                  onClick={() => {
                    console.log('Upload a .csv file')
                  }}
                />
              </Grid>

              <Grid item xs={4}>
                <CallToActionPaper
                  title={'Create manually'}
                  buttonTitle={'Create class'}
                  icon={<LibraryIcon />}
                  onClick={() => {
                    setIsCreateSectionDialogOpened(true)
                  }}
                />
              </Grid>

            </Grid>
          </Paper>
        </Grid>

        {!!sections.length && <Grid item xs={12}>
          <Grid container spacing={2} sx={{ mt: 4 }} alignItems={'center'}>
            {sections.map((section, index) => {
              const studentsCount = section.students.data.length
              const hasStudents = studentsCount > 0
              const studentsWord = studentsCount === 1 ? 'student' : 'students'

              const sectionUrl = calculateUrlForEntity({ type: 'SectionEntity', id: section.id })

              return (
                <React.Fragment key={section.id}>
                  <Grid item xs={11}>
                    <Paper key={index} sx={{ pt: 2, pb: 2, pl: 3, pr: 2 }} elevation={2}>
                      <Grid container spacing={2} alignItems={'stretch'}>
                        <Grid item xs={6} mt={1}>
                          <Typography variant={'x-body-bold'}>{section.name}</Typography>
                          {section.externalId &&
                            <StaticImage src="../../assets/images/clever.png" alt={'Clever'} height={12} style={{ marginTop: '6px' }} />
                          }
                        </Grid>

                        <Grid item xs={4} mt={1}>
                          <Typography variant={'body1'}>{hasStudents ? `${studentsCount} ${studentsWord}` : 'No students'}</Typography>
                        </Grid>

                        <Grid item xs={2}>
                          <Button variant={'contained'} color='tertiary' onClick={() => { navigate(sectionUrl) }} fullWidth> View </Button>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>

                  <Grid item xs={1}>
                    {!section.externalId && <RemoveIcon />}
                  </Grid>
                </React.Fragment>
              )
            })}
          </Grid>
        </Grid>}
      </Grid>

      <SyncWithLMSDialog
        open={isSyncWithLMSDialogOpened}
        onCancel={() => setIsSyncWithLMSDialogOpened(false)}
        onSubmit={() => navigate(cleverUrl)}
      />

      <EditSectionDialog
        open={isCreateSectionDialogOpened}
        onCancel={() => setIsCreateSectionDialogOpened(false)}
        onSubmit={() => { console.log('Create Section') }}
      />
    </>
  )
}

export default ClassroomSection
