import React from 'react'
import PropTypes from 'prop-types'

import { GeneralPaper } from './index'

const ExpandablePaper = props => {
  const { accentedHeader, title, children, open, variant, ...rest } = props

  return (
    <GeneralPaper
      accentedHeader={accentedHeader}
      title={title}
      content={children}
      expandable={true}
      open={open}
      variant={variant}
      {...rest}
      sx={{
        pl: 3,
      }}
    />
  )
}

ExpandablePaper.propTypes = {
  accentedHeader: PropTypes.bool,
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  open: PropTypes.bool,
  variant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
}

ExpandablePaper.defaultProps = {
  accentedHeader: false,
  open: false,
  variant: 'standard',
}

export default ExpandablePaper
