import React from 'react'
import PropTypes from 'prop-types'

const List = props => {
  const processItems = (items = []) => {
    return items.map((item, index) => {
      return <li key={index}>{item}</li>
    })
  }

  const { style, items, ...rest } = props

  const processedItems = processItems(items)

  switch (style) {
    case 'unordered':
      return <ul {...rest}>{processedItems}</ul>
    case 'ordered':
      return <ol {...rest}>{processedItems}</ol>
    default:
      console.warn('Unsupported list style', style)
      return <></>
  }
}

List.propTypes = {
  style: PropTypes.oneOf(['ordered', 'unordered']),
  items: PropTypes.array,
}

List.defaultProps = {
  style: 'unordered',
  items: [],
}

export default List;
