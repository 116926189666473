import React from 'react'
import PropTypes from 'prop-types'

import { IconButton } from '@mui/material'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'

const PaperToggleAction = props => {
  const { onClick, open } = props

  return (
    <IconButton onClick={onClick} variant={'text'} color={'tertiary'}>
      {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
    </IconButton>
  )
}

PaperToggleAction.propTypes = {
  onClick: PropTypes.func,
  open: PropTypes.bool,
}

export default PaperToggleAction
