import React from 'react'
import PropTypes from 'prop-types'

import { stringifyTime } from '@lib/utils/stringify-time'
import { LearningIcon } from '@components/icons'

import { GeneralChip } from './general-chip'

export const LearningDurationChip = ({ duration, sx, ...rest }) => {
  return <GeneralChip label={stringifyTime(duration, 'Learning')} icon={<LearningIcon />} sx={sx} {...rest} />
}

LearningDurationChip.propTypes = {
  duration: PropTypes.object.isRequired,
  sx: PropTypes.object,
}

LearningDurationChip.defaultProps = {
  sx: {},
}
