import React from 'react'
import PropTypes from 'prop-types'

import { joinObjects, transformCollection } from '@lib/utils/index'

import { GeneralChip } from './general-chip'

export const GradesChip = ({ grades, sx }) => {
  const processedGrades = transformCollection(grades)
  const gradesString = joinObjects(processedGrades, 'name', ' | ')

  return <GeneralChip label={gradesString} sx={sx} />
}

GradesChip.propTypes = {
  grades: PropTypes.object,
  sx: PropTypes.object,
}

GradesChip.defaultProps = {
  sx: {},
  grades: {},
}
