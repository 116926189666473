import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from "@mui/material";

const Header = ({ text, level, ...rest }) => {
  const variant = `h${level}`
  return <Typography variant={variant} {...rest}>{text}</Typography>
}

Header.propTypes = {
  text: PropTypes.string,
  level: PropTypes.number,
}

Header.defaultProps = {
  text: '',
  level: 1,
}

export default Header;
