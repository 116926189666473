import React from 'react'
import { Router } from '@reach/router'

import { PrivateRoute } from '@lib/route'

import Seo from '@components/seo'

import Layout from '@steamhub/layouts/layout'

import Competitions from '@steamhub/pages/competitions'
import Dashboard from '@steamhub/pages/dashboard'
import Help from '@steamhub/pages/help'
import Library from '@steamhub/pages/library'
import ProfessionalDevelopment from '@steamhub/pages/professional-development'
import Profile from '@steamhub/pages/profile'
import Project from '@steamhub/pages/project'
import Lesson from '@steamhub/pages/lesson'
import Activity from '@steamhub/pages/activity'
import Resource from '@steamhub/pages/resource'
import Page from '@steamhub/pages/page'
import Room from '@steamhub/pages/room'
import Section from '@steamhub/pages/section'

const SteamHubPage = () => {
    return (
        <Layout>
            <Seo seo={{ metaTitle: 'Steamhub' }} />

            <Router basepath="/">
                <PrivateRoute path="/" component={Dashboard} />
                <PrivateRoute path="/competitions" component={Competitions} />
                <PrivateRoute path="/help" component={Help} />


                <PrivateRoute path="/projects" component={Library} />
                <PrivateRoute path="/projects/:id" component={Project} />

                <PrivateRoute path="/lessons" component={Library} />
                <PrivateRoute path="/lessons/:id" component={Lesson} />

                <PrivateRoute path="/activities" component={Library} />
                <PrivateRoute path="/activities/:id" component={Activity} />

                <PrivateRoute path="/resources" component={Library} />
                <PrivateRoute path="/resources/:id" component={Resource} />

                <PrivateRoute path="/professional-development" component={ProfessionalDevelopment} />

                <PrivateRoute path="/profile" component={Profile} />

                <PrivateRoute path="/p/:slug" component={Page} />
                <PrivateRoute path="/r/:slug" component={Room} />

                <PrivateRoute path="/section/:id" component={Section} />

            </Router>
        </Layout>
    )
}

export default SteamHubPage;
