import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { Box, Button, Grid, Stack, Typography } from '@mui/material'
import { navigate } from 'gatsby'

import { useGetLessonQuery } from '@store/api/steamhub/lesson.api.graphql'

import { useNav } from '@lib/hooks/index'
import {
  transformCollection,
  transformEntity,
  calculateUrlForEntity,
  calculateImageUrl,
} from '@lib/utils/index'

import Gallery from '@components/Gallery'
import { RichText } from '@components/RichText'
import { Loader } from '@components/Loader'
import { Paper } from '@components/Paper'

import { LearningDurationChip, PreparationDurationChip, GradesChip } from '@steamhub/components/chips'
import { LearningGoalsPaper, MaterialsPaper, SubjectsPaper, TOCPaper, SectionsNavigatorPaper } from '@steamhub/components/papers'
import { LessonPlanSection, LessonRunSection, CommonSection } from '@steamhub/components/sections'
import { HorizontalCard } from '@steamhub/components/cards/horizontal-card'

const Lesson = ({ ...props }) => {
  const { setPage } = useNav()
  const { id } = props

  const { data, isLoading, isError, refetch } = useGetLessonQuery(id)

  useEffect(() => {
    setPage('library')
  })

  let lesson = null
  let parentProject = null

  let subjects = null

  let galleryItems = null
  let sectionStep = 0

  let hasGallery = false
  let mainGalleryImage = null
  let restGalleryImages = null

  if (data?.lesson?.data) {
    lesson = transformEntity(data.lesson.data)

    // gallery
    if (lesson.gallery?.main?.data) {
      hasGallery = true
      mainGalleryImage = lesson.gallery.main.data.attributes
      restGalleryImages = transformCollection(lesson.gallery.files)
      galleryItems = [mainGalleryImage, ...restGalleryImages]
    }
    // parent project
    if (lesson.project?.data) {
      parentProject = transformEntity(lesson.project.data)
    }

    if (lesson.subjects.data?.length) {
      subjects = transformCollection(lesson.subjects)
    }
  }

  return (
    <>
      <Button
        onClick={() => navigate(-1)}
        size={'small'}
        variant={'text'}
        color={'secondary'}
        sx={{
          mt: -3,
          p: 0,
        }}
      >
        &lt; Go back
      </Button>

      <Grid container spacing={2}>
        {isLoading && (
          <Grid item xs={12}>
            <Loader />
          </Grid>
        )}

        {isError && (
          <Grid item xs={12}>
            Error happened
          </Grid>
        )}

        {lesson && (
          <>
            <Grid item xs={4}>
              {hasGallery && <Gallery items={galleryItems} />}
            </Grid>

            <Grid item xs={8}>
              <Stack spacing={2}>
                <Box>
                  {lesson.preparationDuration && (
                    <PreparationDurationChip duration={lesson.preparationDuration} sx={{ mr: 2 }} />
                  )}

                  {lesson.learningDuration && (
                    <LearningDurationChip duration={lesson.learningDuration} sx={{ mr: 2 }} />
                  )}

                  {!!lesson.grades.data.length && <GradesChip grades={lesson.grades} sx={{ mr: 2 }} />}
                </Box>

                <Typography variant={'x-headline1-bold'}> {lesson.name} </Typography>

                <Box sx={{ pb: 2 }}>
                  <RichText data={lesson.description} />
                </Box>

                {!!lesson.goals.length && <LearningGoalsPaper goals={lesson.goals} />}

                {!!lesson.materials.length && <MaterialsPaper materials={lesson.materials} />}

                {subjects && !!subjects.length && <SubjectsPaper subjects={subjects} />}

                {!!lesson.tableOfContents?.length && <TOCPaper toc={lesson.tableOfContents} />}
              </Stack>
            </Grid>

            <Grid item xs={12}></Grid>

            {/*Parent project section*/}
            {parentProject && (
              <Grid item xs={12}>
                <Paper variant={'filled'}>
                  <Grid container spacing={2} alignItems={'center'}>
                    <Grid item xs={4}>
                      <HorizontalCard
                        title={parentProject.name}
                        onClick={() => navigate(calculateUrlForEntity({ type: 'ProjectEntity', id: parentProject.id }))}
                        media={calculateImageUrl(parentProject.preview.data?.attributes, 'thumbnail')}
                      />
                    </Grid>
                    <Grid item xs={8} sx={{ textAlign: 'center' }}>
                      <Typography variant={'x-headline5-bold'}>This lesson is part of the following project</Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            )}

            {/*navigation*/}
            {lesson.sections.length > 1 && (
              <Grid item xs={12} sx={{ mt: 4 }}>
                <SectionsNavigatorPaper sections={lesson.sections} />
              </Grid>
            )}

            {/*sections*/}
            <Grid item xs={12} sx={{ mt: 4 }}>
              {lesson.sections.map((section, index) => {
                switch (section.__typename) {
                  case 'ComponentSectionsLessonPlanSection':
                    return (
                      <LessonPlanSection
                        key={index}
                        step={section.enumerable ? ++sectionStep : null}
                        section={section}
                      />
                    )

                  case 'ComponentSectionsLessonRunSection':
                    return (
                      <LessonRunSection
                        key={index}
                        step={section.enumerable ? ++sectionStep : null}
                        section={section}
                      />
                    )

                  case 'ComponentSectionsCommonSection':
                    return (
                      <CommonSection key={index} step={section.enumerable ? ++sectionStep : null} section={section} />
                    )

                  default:
                    console.error('Unknown section type', section.__typename)
                    return <></>
                }
              })}
            </Grid>

            {/*how-to videos*/}

            {/*resources*/}
          </>
        )}
      </Grid>
    </>
  )
}

Lesson.propTypes = {
  id: PropTypes.string.isRequired,
}
export default Lesson
