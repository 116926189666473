import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import { Autocomplete, Grid, InputAdornment, Popover, TextField, Typography } from '@mui/material'
import { Search as SearchIcon } from '@mui/icons-material'
import { Image as MuiImage } from 'mui-image'

import { calculateUrlForEntity, calculateImageUrl } from '@lib/utils/urls'
import { Link } from '@components/Link'

import { useSearchQuery, useLazySearchQuery } from '@store/api/steamhub/search.api.graphql'

export const Search = () => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const [searchResults, setSearchResults] = React.useState([])

  const searchRef = useRef(null)

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const [runSearchQuery, queryResult, lastPromiseInfo] = useLazySearchQuery()

  const handleOpenAutocompletion = event => {
    setAnchorEl(searchRef.current)
  }

  const handleCloseAutocompletion = () => {
    setAnchorEl(null)
  }

  const processResults = results => {
    let processedResults = []

    for (const [key, value] of Object.entries(results)) {
      processedResults = processedResults.concat(value.data)
    }

    return processedResults
  }

  const handleSearch = async (value, event) => {
    let processedResult
    if (value && value.length > 2) {
      const { data } = await runSearchQuery(value)
      processedResult = processResults(data.search)
      setSearchResults(processedResult)
      handleOpenAutocompletion(event)
    } else {
      setSearchResults([])
      handleCloseAutocompletion()
    }
  }

  return (
    <>
      <TextField
        variant="filled"
        fullWidth={true}
        placeholder="Search"
        size={'large'}
        sx={{ p: 0, m: 0, width: '300px' }}
        autoComplete={'off'}
        ref={searchRef}
        onKeyUp={event => {
          handleSearch(event.target.value, event)
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        disableAutoFocus={true}
        onClose={handleCloseAutocompletion}
        sx={{ marginTop: '3px', borderTopLeftRadius: '0px', borderTopRightRadius: '0px' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Grid container spacing={2} alignItems={'center'} sx={{ width: '270px', mt: 1, mb: 2, ml: 2, mr: 2 }}>
          {searchResults.length > 0 ? (
            searchResults.map((item, index) => {
              const link = calculateUrlForEntity({ type: item.__typename, id: item.id })

              let preview
              if (item.attributes.preview?.data?.attributes) {
                preview = calculateImageUrl(item.attributes.preview.data.attributes, 'small')
              }

              return (
                <React.Fragment key={index}>
                  <Grid item xs={3}>
                    {!!preview && (
                      <MuiImage src={preview} alt={''} showLoading={false} duration={50} style={{ borderRadius: '20px' }} />
                    )}
                  </Grid>

                  <Grid item xs={9}>
                    <Typography>
                      <Link to={link}>{item.attributes.name}</Link>
                    </Typography>
                  </Grid>
                </React.Fragment>
              )
            })
          ) : (
            <Grid item xs={12}>
              <Typography sx={{ p: 2 }}>No results found</Typography>
            </Grid>
          )}
        </Grid>
      </Popover>
    </>
  )
}

Search.propTypes = {
  query: PropTypes.string,
  locale: PropTypes.string,
}
