import React from 'react'
import PropTypes from 'prop-types'
import { Box, Button, Grid, Typography } from '@mui/material'

import Card from '@components/Card/card'
import CardMedia from '@components/Card/card-media'
import { Image } from 'mui-image'

export const HorizontalCard = props => {
  const { media, direction, title, actionTitle, onClick } = props

  return (
    <Card media={''} mediaStyle={'none'} onClick={onClick} corners={{}}>
      <Grid
        container
        spacing={2}
        direction={direction === 'ltr' ? 'row' : 'row-reverse'}
        alignItems="stretch"
        sx={{
          minHeight: '158px',
        }}
      >
        <Grid
          item
          sm={12}
          lg={media ? 8 : 12}
          sx={{ textAlign: 'center' }}
          container
          direction={'column'}
          justifyContent="center"
          alignItems="stretch"
        >
          {title && (
            <Grid item sx={{ textAlign: 'center' }}>
              <Typography variant={'x-body-bold'} color={'grey.700'}>
                {title}
              </Typography>
            </Grid>
          )}

          {onClick && (
            <Grid item sx={{ pt: 2 }}>
              <Button variant="contained" color="tertiary" onClick={onClick}>
                <Typography variant={'x-input2-bold'} noWrap>
                  {actionTitle}
                </Typography>
              </Button>
            </Grid>
          )}
        </Grid>

        {media && (
          <Grid item sm={12} lg={4}>
            <Image
              src={media}
              duration={500}
              onClick={onClick ? onClick : null}
              style={{
                borderRadius: '20px',
                maxWidth: '100%',
                maxHeight: '145px',
                width: 'auto',
                height: 'auto',
                cursor: onClick ? 'pointer' : 'default',
              }}
            />
            {/*<CardMedia media={media} mediaStyle={'inline-fit'}  onClick={onClick}/>*/}
          </Grid>
        )}
      </Grid>
    </Card>
  )
}

HorizontalCard.propTypes = {
  media: PropTypes.node,
  direction: PropTypes.oneOf(['ltr', 'rtl']),
  title: PropTypes.string,
  actionTitle: PropTypes.string,
  onClick: PropTypes.func,
}

HorizontalCard.defaultProps = {
  media: null,
  direction: 'ltr',
  title: '',
  actionTitle: 'Check it out',
}
