import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'

const PaperTitle = props => {
  const { children, ...rest } = props

  return <Grid item>{children}</Grid>
}

PaperTitle.propTypes = {
  children: PropTypes.node,
}

export default PaperTitle
