import React from "react";
import PropTypes from "prop-types";
import { MenuItem } from "@mui/material";

import BasicFilter from "./helpers/basic-filter";

const OwnerFilter = props => {
  const { filterOptions, filters, setFilters } = props;
  const { title, multiple } = filterOptions;


  return (
    <BasicFilter name={"owner"} multiple={multiple} setFilters={setFilters} filters={filters}>
      <MenuItem value={0}>{title}</MenuItem>

      <MenuItem value="created-by-me">Created By Me</MenuItem>
      <MenuItem value="created-by-others">Created By Others</MenuItem>
    </BasicFilter>
  )
}

OwnerFilter.propTypes = {
  filterOptions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    multiple: PropTypes.bool.isRequired
  }),

  setFilters: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired
}

OwnerFilter.defaultProps = {
}

export default OwnerFilter
