import React, { useEffect } from 'react'

import { useNav } from '@lib/hooks/index'

import { GreetingSection } from './dashboard/greeting-section'
import { CollectionsSection } from './dashboard/collections-section'
import { InspirationSection } from './dashboard/inspiration-section'

import { useGetDashboardDataQuery } from '@store/api/steamhub/dashboard.api.graphql'
import { transformEntity } from '@lib/utils/transform'

const Dashboard = ({ ...props }) => {
  const { setPage } = useNav()

  let entireDashboardData = {}
  let greetingSectionData = {}
  let collectionsSectionData = {}
  let inspirationSectionData = {}


  const { data, isLoading, isError, refetch } = useGetDashboardDataQuery()

  useEffect(() => {
    setPage('dashboard')
  })

  if (!isLoading && data) {
    entireDashboardData = transformEntity(data.dashboard.data)
    greetingSectionData = entireDashboardData.greeting
    collectionsSectionData = entireDashboardData.collections
    inspirationSectionData = entireDashboardData.inspiration
  }

  return (
    <>
      <GreetingSection data={greetingSectionData} loading={isLoading} />
      <CollectionsSection data={collectionsSectionData} loading={isLoading} />
      <InspirationSection data={inspirationSectionData} loading={isLoading} />
    </>
  )
}

export default Dashboard
