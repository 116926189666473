import React from 'react'
import PropTypes from 'prop-types'

import { GeneralPaper } from './index'

const Paper = props => {
  const { children, variant, ...rest } = props

  return (
    <GeneralPaper variant={variant} {...rest}>
      {children}
    </GeneralPaper>
  )
}

Paper.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
}

Paper.defaultProps = {
  variant: 'standard',
}

export default Paper
