import React from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'

import { Box, Button, Grid, Typography } from '@mui/material'

import { calculateUrlForEntity, calculateImageUrl } from '@lib/utils/urls'
import Card from '@components/Card/card'

import { transformCollection } from '@lib/utils/transform'
import { joinObjects } from '@lib/utils/join-objects'

export const ResourceCard = ({ resource, titleOnTop = false, ...rest }) => {
  const { subjects } = resource
  const transformedSubjects = transformCollection(subjects)

  const previewImage = resource.preview.data
  let media = null

  if (previewImage) {
    media = calculateImageUrl(previewImage.attributes, 'small')
  }

  const targetUrl = calculateUrlForEntity({ type: 'ResourceEntity', id: resource.id })

  const onClick = () => {
    navigate(targetUrl)
  }

  return (
    <Card
      media={media || ''}
      mediaStyle={media ? 'overflow' : 'none'}
      onClick={onClick}
      header={
        media && titleOnTop ? (
          <Box sx={{ alignItems: 'center', mb: 2 }}>
            <Typography variant={'x-body-bold'} color={'grey.700'}>
              {resource.name}
            </Typography>
          </Box>
        ) : null
      }
      footer={
        <Box sx={{ textAlign: 'center', mt: 1 }} title={'Subjects'}>
          <Typography variant={'x-xsmall-bold'} color={'grey.400'}>
            {joinObjects(transformedSubjects, 'code', ' | ')}
          </Typography>
        </Box>
      }
      {...rest}
    >
      <Grid container alignItems={'center'} direction={'column'} spacing={2} sx={{ marginTop: 0, height: '100%' }}>
        {!(media && titleOnTop) && (
          <Grid item sx={{ textAlign: 'center' }}>
            <Typography variant={'x-body-bold'} color={'grey.700'}>
              {resource.name}
            </Typography>
          </Grid>
        )}

        <Grid item>
          <Button variant={'contained'} color={'tertiary'} size={'small'} onClick={onClick}>
            Open Resource
          </Button>
        </Grid>
      </Grid>
    </Card>
  )
}

ResourceCard.propTypes = {
  resource: PropTypes.object,
}
