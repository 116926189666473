import React from 'react'
import PropTypes from 'prop-types'

import { Image } from 'mui-image'
import { Box, Grid } from '@mui/material'
import { styled } from '@mui/material/styles'

const CardMedia = ({ type, media, alt, height, lt, rt, lb, rb, onClick, mediaStyle, overflowMargin, radius }) => {
  const Corner = styled(Box)({
    position: 'absolute',
    zIndex: 40,
  })

  const cornersOffset = `${radius / 2}px`

  return (
    <Grid
      item
      sx={{
        height: `${height}px`,
        marginBottom: `${overflowMargin}px`,
        textAlign: 'center',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: mediaStyle === 'inline-fit' ? 'block' : 'inline-block',
          margin: '0 auto',
          position: 'relative',
          zIndex: 100,
          cursor: onClick ? 'pointer' : 'default',
        }}
        onClick={onClick}
      >
        {lt && <Corner sx={{ left: cornersOffset, top: cornersOffset }}>{lt}</Corner>}
        {rt && <Corner sx={{ right: cornersOffset, top: cornersOffset }}>{rt}</Corner>}
        {lb && <Corner sx={{ left: cornersOffset, bottom: cornersOffset }}>{lb}</Corner>}
        {rb && <Corner sx={{ right: cornersOffset, bottom: cornersOffset }}>{rb}</Corner>}

        <Image
          src={media}
          alt={alt}
          duration={100}
          fit={mediaStyle === 'inline-fit' ? 'cover' : 'unset'}
          showLoading={true}
          style={{
            borderRadius: `${radius}px`,
            height: `${height}px`,
            width: '100%',
            maxWidth: '100%',
            margin: '0 auto',
          }}
        />
      </Box>
    </Grid>
  )
}

CardMedia.propTypes = {
  alt: PropTypes.string,
  height: PropTypes.number,
  media: PropTypes.string.isRequired,
  mediaStyle: PropTypes.oneOf(['overflow', 'inline', 'inline-fit', 'none']),
  onClick: PropTypes.func,
  overflowMargin: PropTypes.number,
  radius: PropTypes.number,
  type: PropTypes.oneOf(['image', 'video', 'icon']).isRequired,

  lt: PropTypes.node,
  rt: PropTypes.node,
  lb: PropTypes.node,
  rb: PropTypes.node,
}

CardMedia.defaultProps = {
  alt: 'Main image',
  mediaStyle: 'overflow',
  onClick: () => {},
  overflowMargin: 0,
  radius: 20,
  type: 'image',
}

export default CardMedia
