import React from 'react'
import PropTypes from 'prop-types'
import { Table, TableCell, TableContainer, TableHead, TableRow, TableBody, Typography } from '@mui/material'
import { Paper } from '@components/Paper'
import parse from 'html-react-parser'

const Header = ({ content, ...rest }) => {
  return (
    <TableContainer sx={{ minWidth: 650 }} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
          {content[0].map((cell, index) => (
            <TableCell key={index}>
              <Typography variant="h6">{parse(cell)}</Typography>
            </TableCell>
          ))}
          </TableRow>
        </TableHead>

        <TableBody>
        {content.slice(1).map((row, index) => (
          <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            {row.map((cell, index) => (
              <TableCell key={index}>{parse(cell)}</TableCell>
            ))}
          </TableRow>
        ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

Header.propTypes = {
  content: PropTypes.array,
}

Header.defaultProps = {
  content: [],
}

export default Header
