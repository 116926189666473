import React from 'react'
import PropTypes from 'prop-types'

import parse from 'html-react-parser'

import { Box } from '@mui/material'

const Paragraph = props => {
  const { text, alignment, ...rest } = props

  return (
    <Box {...rest} sx={{ pt: 1, pb: 1, textAlign: alignment }}>
      {parse(text)}
    </Box>
  )
}

Paragraph.propTypes = {
  text: PropTypes.string,
  alignment: PropTypes.string,
}

Paragraph.defaultProps = {
  text: '',
  alignment: 'left',
}

export default Paragraph
