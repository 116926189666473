import React from 'react'
import PropTypes from 'prop-types'
import { Chip } from '@mui/material'

export const GeneralChip = props => {
  const { icon, label, sx, ...rest } = props

  return (
    <Chip
      label={label}
      icon={icon}
      variant="filled"
      size={'small'}
      color={'secondary'}
      sx={{
        color: 'grey.700',
        backgroundColor: 'white',
        borderRadius: '5px',
        typography: 'x-tiny-black',
        ...sx,
      }}
      {...rest}
    />
  )
}

GeneralChip.propTypes = {
  icon: PropTypes.node,
  label: PropTypes.string.isRequired,
  sx: PropTypes.object,
}

GeneralChip.defaultProps = {
  sx: {},
  icon: null,
}
