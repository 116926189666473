import React, { useState } from 'react'
import { Button, Grid, TextField, Typography } from '@mui/material'
import { useAuth } from '@lib/auth'

import ChangeEmailDialog from '@steamhub/components/dialogs/change-email-dialog'
import ChangePasswordDialog from '@steamhub/components/dialogs/change-password-dialog'

const AccountSection = () => {
  const { user } = useAuth()
  const { email } = user

  const [isChangeEmailDialogOpened, setIsChangeEmailDialogOpened] = useState(false)
  const [isChangePasswordDialogOpened, setIsChangePasswordDialogOpened] = useState(false)

  const handleChangeEmail = () => {
    setIsChangeEmailDialogOpened(true)
  }

  const handleChangePassword = () => {
    setIsChangePasswordDialogOpened(true)
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant={'x-headline2-bold'}>Account</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant={'body1'}>You are signed in as</Typography>
        </Grid>

        <Grid item xs={9}>
          <TextField
            type={'text'}
            variant={'filled'}
            size={'large'}
            value={email}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <Button variant="contained" color="tertiary" fullWidth size={'large'} onClick={handleChangeEmail} disabled>
            Change email
          </Button>
        </Grid>

        <Grid item xs={12} mt={3}>
          <Typography variant={'body1'}>Password</Typography>
        </Grid>

        <Grid item xs={9}>
          <TextField
            type={'password'}
            variant={'filled'}
            value={''}
            placeholder={'•••••••••••••••••••'}
            size={'large'}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <Button variant="contained" color="tertiary" size={'large'} fullWidth onClick={handleChangePassword}>
            Change password
          </Button>
        </Grid>
      </Grid>

      <ChangeEmailDialog open={isChangeEmailDialogOpened} onCancel={() => setIsChangeEmailDialogOpened(false)} />

      <ChangePasswordDialog
        open={isChangePasswordDialogOpened}
        onCancel={() => setIsChangePasswordDialogOpened(false)}
      />
    </>
  )
}

export default AccountSection
