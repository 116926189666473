import React from 'react'
import SimpleDialog, { propTypes } from '@components/Dialog/simple-dialog'
import { Typography, Grid, TextField } from '@mui/material'

const EditSectionDialog = props => {
  const [sectionName, setSectionName] = React.useState('')

  const handleSectionNameChange = event => {
    setSectionName(event.target.value)
  }

  return <SimpleDialog submitButtonTitle={'Create'} {...props} allowSubmit={!!sectionName.length}>
    <Grid container spacing={2}>
      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <Typography variant="h3">Create class</Typography>
      </Grid>

      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <TextField label="Class name" variant="standard" size='large' fullWidth value={sectionName} onChange={handleSectionNameChange} />
      </Grid>
    </Grid>
  </SimpleDialog>
}

EditSectionDialog.propTypes = propTypes

export default EditSectionDialog
