import React from 'react'
import PropTypes from 'prop-types'

import { Box, Grid } from '@mui/material'

import CardMedia from '@components/Card/card-media'
import { Paper } from '@components/Paper'

const Card = props => {
  const { header, footer, media, content, children, extraHeader, extraFooter, corners, onClick } = props
  let { mediaStyle } = props

  const radius = 20
  let mediaHeight = 200
  let mediaOverflowHeight = 80

  let mediaMargin = 0
  let additionalMediaContainerMargin = 0

  if (!media) {
    mediaStyle = 'none'
  }

  if (mediaStyle === 'overflow') {
    mediaMargin = mediaOverflowHeight - mediaHeight
    additionalMediaContainerMargin = 16
  }

  // mediaStyle = 'overflow' // 'overflow', 'inline', 'inline-fit', 'none'

  const mediaComponent = (
    <CardMedia
      media={media}
      mediaStyle={mediaStyle}
      height={mediaHeight}
      overflowMargin={mediaMargin - additionalMediaContainerMargin}
      radius={radius}
      onClick={onClick}
      {...corners}
    />
  )

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Grid container direction={'column'} sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        {/*MEDIA Placed here in case of overflow*/}
        {mediaStyle === 'overflow' && mediaComponent}

        {/*EXTRA FOOTER*/}
        {extraHeader && mediaStyle !== 'overflow' && (
          <Grid
            item
            sx={{
              zIndex: 10,
              marginBottom: `${-radius}px`,
              paddingBottom: `${radius}px`,
            }}
          >
            {extraHeader}
          </Grid>
        )}

        {/*CONTENT*/}
        <Grid
          item
          sx={{
            flex: 1,
            zIndex: 20,
          }}
        >
          <Paper
            sx={{
              height: '100%',
              display: 'flex',
            }}
          >
            <Grid container direction={'column'} sx={{ height: '100%' }}>
              {/* TITLE Shown in case of non overflow card */}
              {header && mediaStyle !== 'overflow' && <Grid item>{header}</Grid>}

              {/*MEDIA Placed here in case of inline*/}
              {(mediaStyle === 'inline' || mediaStyle === 'inline-fit') && mediaComponent}

              <Grid item sx={{ marginTop: `${-mediaMargin}px`, flexGrow: 1 }}>
                {content || children}
              </Grid>

              {footer && <Grid item>{footer}</Grid>}
            </Grid>
          </Paper>
        </Grid>

        {/*EXTRA FOOTER*/}
        {extraFooter && (
          <Grid
            item
            sx={{
              zIndex: 10,
              marginTop: `${-radius}px`,
              paddingTop: `${radius}px`,
            }}
          >
            {extraFooter}
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

Card.propTypes = {
  children: PropTypes.node,
  content: PropTypes.node,
  corners: PropTypes.shape({
    lt: PropTypes.node,
    rt: PropTypes.node,
    lb: PropTypes.node,
    rb: PropTypes.node,
  }),
  extraHeader: PropTypes.node,
  extraFooter: PropTypes.node,
  footer: PropTypes.node,
  header: PropTypes.node,
  media: PropTypes.node || PropTypes.instanceOf(CardMedia),
  mediaStyle: PropTypes.oneOf(['overflow', 'inline', 'inline-fit', 'none']),
  onClick: PropTypes.func,
}

Card.defaultProps = {
  content: null,
  corners: {},
  children: null,
  extraHeader: null,
  extraFooter: null,
  footer: null,
  header: null,
  media: null,
  mediaStyle: 'overflow',
  onClick: null,
}

export default Card
