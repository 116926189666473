import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Fab, Grid, Typography, Skeleton } from '@mui/material'
import { styled } from '@mui/system'
import { NavigateBefore, NavigateNext } from '@mui/icons-material'
import { RichText } from '@components/RichText'
import { transformCollection, transformEntity } from '@lib/utils/transform'
import { AutomaticCard } from '@steamhub/components/cards'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'

export const CollectionsSection = ({ data, loading, ...rest }) => {
  const slidesPerView = 3

  const toggles = []

  const NavButton = styled(Button)(({ theme }) => ({
    borderRadius: '40px',
    marginLeft: '16px',
  }))

  const [swiperRef, setSwiperRef] = useState()

  const handleLeftClick = useCallback(() => {
    if (!swiperRef) return
    swiperRef.slidePrev()
  }, [swiperRef])

  const handleRightClick = useCallback(() => {
    if (!swiperRef) return
    swiperRef.slideNext()
  }, [swiperRef])

  const [activeToggle, setActiveToggle] = React.useState(0)

  if (data && !loading && data.tags?.length) {
    // we found tags to be shown
    data.tags.forEach(collectionTag => {
      const tag = transformEntity(collectionTag.tag.data)
      const slug = tag.slug

      const tagProjects = transformCollection(tag.projects)
      const tagLessons = transformCollection(tag.lessons)
      const tagActivities = transformCollection(tag.activities)
      const tagResources = transformCollection(tag.resources)

      // join arrays and shuffle
      const tagItems = [].concat(tagProjects, tagLessons, tagActivities, tagResources)
      const shuffledItems = tagItems.sort(() => 0.5 - Math.random())

      if (shuffledItems.length) {
        toggles.push({
          name: collectionTag.name,
          slug: slug,
          items: shuffledItems,
        })
      }
    })
  }

  return (
    <Grid container direction={'column'} spacing={2} justifyContent={'center'} sx={{ mt: 6, textAlign: 'center' }}>
      <Grid item>
        <Typography variant={'x-headline1-semibold'}>
          {loading ? <Skeleton sx={{ ml: 5, mr: 5 }} /> : data.title}
        </Typography>
      </Grid>

      <Grid item>
        <Typography variant={'x-body-regular'}>
          {loading ? (
            <Skeleton variant={'rectangular'} height={'150px'} sx={{ ml: 5, mr: 5 }} />
          ) : (
            <RichText data={data.content} />
          )}
        </Typography>
      </Grid>

      <Grid item sx={{ mt: 2 }}>
        {toggles.map((toggle, index) => {
          return (
            <NavButton
              key={index}
              variant={activeToggle === index ? 'contained' : 'outlined'}
              color={'primary'}
              onClick={() => setActiveToggle(index)}
            >
              {toggle.name}
            </NavButton>
          )
        })}
      </Grid>

      <Grid item container>
        <Grid item xs={12} sx={{ overflow: 'hidden', width: '800px' }}>
          <Swiper
            onSwiper={setSwiperRef}
            slidesPerView={slidesPerView}
            spaceBetween={30}
            grabCursor={true}
            loop={true}
            pagination={{
              clickable: true,
            }}
            modules={[Navigation]}
            className="mySwiper"
          >
            {toggles
              .filter((toggle, index) => index === activeToggle)[0]
              ?.items.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <AutomaticCard item={item} mediaStyle={'inline-fit'} titleOnTop={true} />
                  </SwiperSlide>
                )
              })}
          </Swiper>
        </Grid>
      </Grid>

      <Grid item>
        <Fab size={'large'} disabled={loading} onClick={handleLeftClick}>
          <NavigateBefore />
        </Fab>

        <Fab size={'large'} sx={{ ml: 2 }} disabled={loading} onClick={handleRightClick}>
          <NavigateNext />
        </Fab>
      </Grid>
    </Grid>
  )
}

CollectionsSection.propTypes = {}
