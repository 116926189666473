import React from 'react'
import PropTypes from 'prop-types'
import { Button, Grid, Typography, Skeleton, Box } from "@mui/material";

import { navigate } from '@lib/utils/navigate'

import { Paper } from '@components/Paper'
import { RichText } from '@components/RichText'

export const InspirationSection = ({data, loading, ...rest}) => {
  return (
    <Paper variant={'filled'} sx={{ py: 8, px: 8, mt: 6 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant={'x-headline2-semibold'}>
            {loading ? <Skeleton /> : data.title}
          </Typography>
        </Grid>

        <Grid item xs={12} className={'test-anton'}>
          <Typography variant={'x-body-regular'} className={'down'}>
            {loading ? (
              <Skeleton variant={'rectangular'} height={'150px'} sx={{ ml: 5, mr: 5 }} />
            ) : (
              <RichText data={data.content} />
            )}
          </Typography>
        </Grid>

        <Grid item xs={12} sx={{mt: 2}}>

            <Button
              variant={'contained'}
              color={'tertiary'}
              onClick={() => {
                navigate(data.inspirationAction?.action || '')
              }}
            >
              {loading ? <Skeleton variant={'rectangular'} height={'40px'} width={'200px'} /> : data.inspirationAction?.actionTitle}
            </Button>
        </Grid>
      </Grid>
    </Paper>
  )
}

InspirationSection.propTypes = {}
