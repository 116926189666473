import React from 'react'
import SimpleDialog, { propTypes } from '@components/Dialog/simple-dialog'
import { Grid, TextField } from '@mui/material'

const ChangeEmailDialog = props => {
  return (
    <SimpleDialog title={'Edit email'} {...props}>
      <Grid container spacing={2} columns={3}>
        <Grid item xs={3}>
          <TextField
            name={'email'}
            label={'Email Address'}
            type={'email'}
            variant={'filled'}
            size={'large'}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            name={'password'}
            label="Confirm password"
            type="password"
            variant={'filled'}
            size={'large'}
            fullWidth
          />
        </Grid>
      </Grid>
    </SimpleDialog>
  )
}

ChangeEmailDialog.propTypes = propTypes

export default ChangeEmailDialog
