import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { Box, Grid, Typography } from '@mui/material'

import { useGetPageBySlugQuery } from '@store/api/steamhub/page.api.graphql'

import { useNav } from '@lib/hooks/index'

import { transformCollection, transformEntity } from '@lib/utils/index'

import Gallery from '@components/Gallery'
import { RichText } from '@components/RichText'
import { Image } from 'mui-image'
import { calculateImageUrl } from '@lib/utils/index'

import { CommonSection } from '@steamhub/components/sections'
import { CollectionsSection } from '@steamhub/pages/dashboard/collections-section'

import { Loader } from '@components/Loader'

const Page = ({ ...props }) => {
  const { setPage } = useNav()
  const { slug } = props

  const { data, isLoading, isError, refetch } = useGetPageBySlugQuery(slug)
  let page = data?.pages?.data?.[0] && transformEntity(data.pages.data[0])

  useEffect(() => {
    if (!page) return
    setPage(page.workspace || 'dashboard')
  }, [page])

  if (!page && !isLoading) {
    return <>Error happened</>
  }

  if (isLoading) {
    return <Loader />
  }

  if (isError) {
    return <Typography>Something went wrong</Typography>
  }

  console.log('PAGE', page)
  let sectionStep = 0

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant={'x-headline2-semibold'} sx={{ mb: 2 }}>
          {page.title}
        </Typography>
      </Grid>

      {page.blocks?.map((block, index) => {
        switch (block.__typename) {
          case 'ComponentSectionsCommonSection':
            return (
              <Grid item xs={12} key={index}>
                <CommonSection step={block.enumerable ? ++sectionStep : null} section={block} />
              </Grid>
            )

          case 'ComponentSharedGallery':

            const mainGalleryImage = block.main.data.attributes
            const restGalleryImages = transformCollection(block.files)
            const galleryItems = [mainGalleryImage, ...restGalleryImages]


            return (
              <Grid item xs={12} key={index}>
                <Gallery items={galleryItems} previewsProps={{ justifyContent: 'center' }} />
              </Grid>
            )

          case 'ComponentSharedRichText':
            return (
              <Grid item xs={12} key={index}>
                <RichText data={block.body} />
              </Grid>
            )

          case 'ComponentDashboardCollections':

            return (
              <Grid item xs={12} key={index}>
                <CollectionsSection item xs={12} key={index} loading={false} data={block} />
              </Grid>
            )

          case 'ComponentSharedMedia':
            const imageUrl = calculateImageUrl(block.file.data.attributes)

            return (
              <Grid item xs={12} key={index}>
                <Box sx={{ mt: 1, mb: 2 }}>
                  <Image src={imageUrl} alt={block.file.alt} showLoading={true} style={{ borderRadius: '20px' }} />
                </Box>
              </Grid>
            )

          default:
            console.warn('Unknown block type', block.__typename)
            return null
        }
      })}
    </Grid>
  )
}

Page.propTypes = {
  slug: PropTypes.string.isRequired,
}

export default Page
