import React from "react";
import PropTypes from "prop-types";
import { MenuItem } from "@mui/material";

import BasicFilter from "./helpers/basic-filter";

import { transformCollection } from "@lib/utils/transform";

const GradesFilter = props => {
  const { filterOptions, filters, setFilters } = props;
  const { title, grades, multiple } = filterOptions;

  const transformedGrades = transformCollection(grades);

  return (
    <BasicFilter name={"grades"} multiple={multiple} setFilters={setFilters} filters={filters}>
      <MenuItem value={0}>{title}</MenuItem>

      {transformedGrades.map(grade => (
        <MenuItem key={grade.id} value={grade.id}>
          {grade.name}
        </MenuItem>
      ))}

    </BasicFilter>
  );
};

GradesFilter.propTypes = {
  filterOptions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    multiple: PropTypes.bool.isRequired,

    grades: PropTypes.object
  }),

  setFilters: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired
};

GradesFilter.defaultProps = {
  filterOptions: {
    grades: {}
  }
};

export default GradesFilter;
