import React, { useState } from 'react'
import { Button, Grid } from '@mui/material'
import { Link, navigate } from 'gatsby'
import LogoutDialog from '@steamhub/components/dialogs/logout-dialog'

const LogoutSection = () => {
  const [isLogoutDialogOpened, setIsLogoutDialogOpened] = useState(false)

  const handleLogoutClick = () => {
    console.log('handleLogout')
    setIsLogoutDialogOpened(true)
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Button variant="contained" color="tertiary" onClick={handleLogoutClick} size={'large'} fullWidth>
            Logout
          </Button>
        </Grid>
      </Grid>

      <LogoutDialog
        open={isLogoutDialogOpened}
        dense
        onCancel={() => setIsLogoutDialogOpened(false)}
        onSubmit={() => navigate('/auth/logout')}
      />
    </>
  )
}

export default LogoutSection
