import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { Box, Button, Grid, Stack, Typography } from '@mui/material'

import { navigate } from 'gatsby'

import { useGetActivityQuery } from '@store/api/steamhub/activity.api.graphql'

import { useNav } from '@lib/hooks/index'

import { transformCollection, transformEntity, calculateUrlForEntity, calculateImageUrl } from '@lib/utils/index'

import Gallery from '@components/Gallery'
import { RichText } from '@components/RichText'

import { LearningDurationChip, PreparationDurationChip, GradesChip } from '@steamhub/components/chips'

import { LearningGoalsPaper, MaterialsPaper, SubjectsPaper, TOCPaper, SectionsNavigatorPaper } from '@steamhub/components/papers'

import { CommonSection } from '@steamhub/components/sections'
import { Loader } from '@components/Loader'
import { Paper } from '@components/Paper'
import { HorizontalCard } from '@steamhub/components/cards/horizontal-card'

const Activity = ({ ...props }) => {
  const { setPage } = useNav()
  const { id } = props

  const { data, isLoading, isError, refetch } = useGetActivityQuery(id)

  useEffect(() => {
    setPage('library')
  })

  let activity = null
  let parentProject = null

  let subjects = null

  let galleryItems = null
  let sectionStep = 0

  let hasGallery = false
  let mainGalleryImage = null
  let restGalleryImages = null

  if (data?.activity?.data) {
    activity = transformEntity(data.activity.data)

    // gallery
    if (activity.gallery?.main?.data) {
      hasGallery = true
      mainGalleryImage = activity.gallery.main.data.attributes
      restGalleryImages = transformCollection(activity.gallery.files)
      galleryItems = [mainGalleryImage, ...restGalleryImages]
    }
    // parent project
    if (activity.project?.data) {
      parentProject = transformEntity(activity.project.data)
    }

    if (activity.subjects.data?.length) {
      subjects = transformCollection(activity.subjects)
    }
  }

  return (
    <>
      <Button
        onClick={() => navigate(-1)}
        size={'small'}
        variant={'text'}
        color={'secondary'}
        sx={{
          mt: -3,
          p: 0,
        }}
      >
        &lt; Go back
      </Button>

      <Grid container spacing={2}>
        {isLoading && (
          <Grid item xs={12}>
            <Loader />
          </Grid>
        )}

        {isError && (
          <Grid item xs={12}>
            Error happened
          </Grid>
        )}

        {activity && (
          <>
            <Grid item xs={4}>
              {hasGallery && <Gallery items={galleryItems} />}
            </Grid>

            <Grid item xs={8}>
              <Stack spacing={2}>
                <Box>
                  {activity.preparationDuration && (
                    <PreparationDurationChip duration={activity.preparationDuration} sx={{ mr: 2 }} />
                  )}

                  {activity.learningDuration && (
                    <LearningDurationChip duration={activity.learningDuration} sx={{ mr: 2 }} />
                  )}

                  {!!activity.grades.data.length && <GradesChip grades={activity.grades} sx={{ mr: 2 }} />}
                </Box>

                <Typography variant={'x-headline1-bold'}> {activity.name} </Typography>

                <Box sx={{ pb: 2 }}>
                  <RichText data={activity.description} />
                </Box>

                {!!activity.goals.length && <LearningGoalsPaper goals={activity.goals} />}

                {!!activity.materials.length && <MaterialsPaper materials={activity.materials} />}

                {subjects && !!subjects.length && <SubjectsPaper subjects={subjects} />}

                {!!activity.tableOfContents?.length && <TOCPaper toc={activity.tableOfContents} />}
              </Stack>
            </Grid>

            <Grid item xs={12}></Grid>

            {/*Parent project section*/}
            {parentProject && (
              <Grid item xs={12}>
                <Paper variant={'filled'}>
                  <Grid container spacing={2} alignItems={'center'}>
                    <Grid item xs={4}>
                      <HorizontalCard
                        title={parentProject.name}
                        onClick={() => navigate(calculateUrlForEntity({ type: 'ProjectEntity', id: parentProject.id }))}
                        media={calculateImageUrl(parentProject.preview.data?.attributes, 'thumbnail')}
                      />
                    </Grid>
                    <Grid item xs={8} sx={{ textAlign: 'center' }}>
                      <Typography variant={'x-headline5-bold'}>
                        This activity is part of the following project
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            )}

            {/*navigation*/}
            {activity.sections.length > 1 && (
              <Grid item xs={12} sx={{ mt: 4 }}>
                <SectionsNavigatorPaper sections={activity.sections} />
              </Grid>
            )}

            {/*sections*/}
            <Grid item xs={12} sx={{ mt: 4 }}>
              {activity.sections.map((section, index) => {
                switch (section.__typename) {
                  case 'ComponentSectionsCommonSection':
                    return (
                      <CommonSection key={index} step={section.enumerable ? ++sectionStep : null} section={section} />
                    )

                  default:
                    console.error('Unknown section type', section.__typename)
                    return <></>
                }
              })}
            </Grid>

            {/*how-to videos*/}

            {/*resources*/}
          </>
        )}
      </Grid>
    </>
  )
}

Activity.propTypes = {
  id: PropTypes.string.isRequired,
}

export default Activity
