import React from 'react'
import PropTypes from 'prop-types'

import { Paragraph, Raw, Delimiter, Image, List, Header, Table } from './index'

const RichText = props => {
  const { data } = props

  if (!data) {
    return null
  }

  const sections = []

  try {
    const parsedContent = JSON.parse(data)
    const blocks = parsedContent.blocks

    // console.log("RichText CONTENT", parsedContent)

    // iterate over blocks of parsed content and add them to sections array
    for (const block of blocks) {
      switch (block.type) {
        case 'paragraph':
          sections.push({ id: block.id, data: <Paragraph {...block.data} /> })
          break
        case 'raw':
          sections.push({ id: block.id, data: <Raw {...block.data} /> })
          break
        case 'delimiter':
          sections.push({ id: block.id, data: <Delimiter {...block.data} /> })
          break

        case 'image':
          sections.push({ id: block.id, data: <Image {...block.data} /> })
          break

        case 'list':
          sections.push({ id: block.id, data: <List {...block.data} /> })
          break

        case 'header':
          sections.push({ id: block.id, data: <Header {...block.data} /> })
          break

        case 'table':
          console.log('table', block)
          sections.push({ id: block.id, data: <Table {...block.data} /> })
          break

        // todo Implement the rest of the blocks
        default:
          console.warn('Unknown block type', block.type)
          break
      }
    }
  } catch (e) {
    console.error('Error during parsing rich content', e)
    return <></>
  }

  return (
    <>
      {sections.map(section => (
        <React.Fragment key={section.id}>{section.data}</React.Fragment>
      ))}
    </>
  )
}

RichText.propTypes = {
  data: PropTypes.string,
}

export default RichText
