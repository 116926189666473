import React from 'react'

import { Box, Fab, Typography, Popover } from '@mui/material'
import { NotificationsNone } from '@mui/icons-material'

import { useAuth } from '@lib/auth'
import { navigate } from '@lib/utils/navigate'


import { Search } from './search'

export const TopBar = () => {
    const [anchorEl, setAnchorEl] = React.useState(null)


    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined

    const { user } = useAuth()


    const handleOpenNotifications = event => {
        setAnchorEl(event.currentTarget)
    }

    const handleCloseNotifications = () => {
        setAnchorEl(null)
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexGrow: 1,
                height: '100%',
                alignItems: 'center',
                justifyContent: 'right',
            }}
        >
            <Box mr={'30px'} width={'300px'}>
                <Search />
            </Box>

            <Box mr={'15px'}>
                <Fab color="default" onClick={handleOpenNotifications}>
                    <NotificationsNone />
                </Fab>

                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleCloseNotifications}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <Typography sx={{ p: 2 }}>You don't have any notifications yet</Typography>
                </Popover>
            </Box>

            <Box mr={'60px'}>
                <Typography typography="h6" sx={{ cursor: 'pointer' }} onClick={() => navigate('/profile')}>
                    {user.name ? `Hi, ${user.name}` : user.username ? `Hi, ${user.username}` : 'Hello'}
                </Typography>
            </Box>
        </Box>
    )
}