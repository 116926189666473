import React from 'react'
import PropTypes from 'prop-types'
import { Paper } from '@components/Paper'
import { Divider, Grid, Typography } from '@mui/material'

import AnchorLink from 'react-anchor-link-smooth-scroll'

const SectionsNavigatorPaper = ({ sections }) => {
  let sectionStep = 0

  const availableSections = sections.filter(section => section.enumerable)
  if (!availableSections.length) {
    return null
  }

  const anchors = availableSections.map((section, index) => (
    <AnchorLink key={index} href={`#section-${++sectionStep}`} style={{ textDecoration: 'none', color: 'black' }}>
      <Typography key={index} variant={'x-small-semibold'}>
        {section.title}
      </Typography>
    </AnchorLink>
  ))

  const delimiter = <Divider orientation={'vertical'} />
  const delimitedAnchors = anchors.reduce((acc, anchor, index) => {
    if (index > 0) {
      acc.push(delimiter)
    }
    acc.push(anchor)
    return acc
  }, [])

  return (
    <Paper sx={{ textAlign: 'center' }}>
      <Grid container direction="row" justifyContent="space-evenly" alignItems="stretch">
        {delimitedAnchors.map((anchor, index) => (
          <Grid item key={index}>
            {anchor}
          </Grid>
        ))}
      </Grid>
    </Paper>
  )
}

SectionsNavigatorPaper.propTypes = {
  sections: PropTypes.array,
}

SectionsNavigatorPaper.defaultProps = {
  sections: [],
}

export default SectionsNavigatorPaper
