import React, { useEffect } from 'react'
import { useNav } from '@lib/hooks/index'

const ProfessionalDevelopment = ({ ...props }) => {
  const { setPage } = useNav()

  useEffect(() => {
    setPage('professional-development')
  })

  return <>ProfessionalDevelopment</>
}

export default ProfessionalDevelopment
