import React from "react";
import PropTypes from "prop-types";

import { Button, Grid, Typography } from "@mui/material";
import { Paper } from "@components/Paper";

import { useGetRoomFiltersQuery } from "@store/api/steamhub/room.api.graphql";

import ContentTypeFilter from "./filters/content-type-filter";
import ComplexityFilter from "./filters/complexity-filter";
import GradesFilter from "./filters/grades-filter";
import SubjectsFilter from "./filters/subjects-filter";
import TagsFilter from "./filters/tags-filter";
import SubscriptionsFilter from "./filters/subscriptions-filter";
import FavoriteFilter from "./filters/favorite-filter";
import SharedFilter from "./filters/shared-filter";
import OwnerFilter from "./filters/owner-filter";
import DurationFilter from "./filters/duration-filter";

const Filters = props => {
  const { room, filters, setFilters, clearFilters } = props;

  const { data, isLoading, isError, refetch } = useGetRoomFiltersQuery(room);

  const allFilters = data?.roomFilters.data?.attributes.allowedFilters || [];

  // get only filters that are enabled and editable
  const visibleFilters = allFilters.filter(filter => filter.enabled && filter.editable);

  // render each filter with its own component
  const renderedFilters = visibleFilters
    .map(filterOptions => {
      const filterProps = {
        key: filterOptions.__typename,
        filterOptions,
        filters,
        setFilters
      }

      switch (filterOptions.__typename) {
        case "ComponentFiltersContentTypeFilter":
          return <ContentTypeFilter {...filterProps}/>;

        case "ComponentFiltersComplexityFilter":
          return <ComplexityFilter {...filterProps} />;

        case "ComponentFiltersGradesFilter":
          return <GradesFilter {...filterProps} />;

        case "ComponentFiltersSubjectsFilter":
          return <SubjectsFilter {...filterProps}/>;

        case "ComponentFiltersTagsFilter":
          return <TagsFilter {...filterProps} />;

        case "ComponentFiltersSubscriptionsFilter":
          return <SubscriptionsFilter {...filterProps} />;

        case "ComponentFiltersFavoriteFilter":
          return <FavoriteFilter {...filterProps} />;

        case "ComponentFiltersSharedFilter":
          return <SharedFilter {...filterProps} />;

        case "ComponentFiltersOwnerFilter":
          return <OwnerFilter {...filterProps} />;

        case "ComponentFiltersDurationFilter":
          return <DurationFilter {...filterProps} />;

        default:
          console.warn("Unknown filterOptions type: ", filterOptions.__typename);
          return;
      }
    })
    .filter(filter => filter);

  // todo: Revise
  // set grid item sizes for each filter + reset button
  // Reset button is always the last item

  // - if there are 1 filter - it takes 10 columns + 2 for reset button
  // - if there are 2 filters - each takes 5 columns + 2 for reset button
  // - if there are 3 filters - each takes 3 columns + 3 for reset button
  // - if there are 4 filters - each takes 2 columns + 4 for reset button
  // - if there are 5 filters - each takes 2 columns + 2 for reset button
  // ...

  const sizing = {
    xs: 12,
    sm: 6,
    md: 4,
    lg: 2,
    xl: 2
  };

  const handleClearFilters = async () => {
    clearFilters()
  };

  // check if there are any non-empty filters
  const nonEmptyFilters = Object.keys(filters).filter(filter => (filters[filter]?.length));

  return (
    !!renderedFilters.length && (
      <Paper>
        <Grid container spacing={2} direction="row" justifyContent="space-between" alignItems="stretch">
          {renderedFilters.map((filter, index) => (
            <Grid item {...sizing} key={index}>
              {filter}
            </Grid>
          ))}

          <Grid item {...sizing}>
            <Button
              variant={"contained"}
              color={"tertiary"}
              sx={{ width: "100%" }}

              disabled={!nonEmptyFilters.length}
              onClick={handleClearFilters}
            >
              <Typography variant={"x-input2-semibold"} noWrap>
                Reset filters
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Paper>
    )
  );
};

Filters.propTypes = {
  room: PropTypes.string.isRequired,
  setFilters: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired
};

export default Filters;
