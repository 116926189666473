import React, { useEffect, useState } from "react";

import { Button, Grid, Typography } from "@mui/material";

import { useQueryParamString } from 'react-use-query-param-string';

import { useNav } from "@lib/hooks/index";
import { Loader } from "@components/Loader";
import { transformEntity } from "@lib/utils/index";

import Filters from "@steamhub/pages/room/filters";
import { AutomaticCard } from "@steamhub/components/cards";
import { RichText } from "@components/RichText";

import { useGetRoomDataQuery } from "@store/api/steamhub/room.api.graphql";

const Room = ({ ...props }) => {
  const { setPage } = useNav();
  const { slug } = props;

  const [filtersQuery, setFiltersQuery, queryFilteringIsInitialized] = useQueryParamString('filters', "", true);
  const [filters, setFiltersState] = useState({});
  const [visiblePagesAmount, setVisiblePagesAmount] = useQueryParamString('pages', "1", true);

  const setFilters = (newFilters) => {
    setFiltersState((prevFilters) => {
      const filters = { ...prevFilters, ...newFilters }

      const queryString = Object.entries(filters)
        .filter(([key, value]) => !!value)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join('&');

      setVisiblePagesAmount("1")
      setFiltersQuery(queryString)

      return filters
    });
  }

  const clearFilters = () => {
    setFiltersQuery("");
    setFiltersState({});
  }

  const parseFilters = (filtersQuery) => {

    const decodedFiltersQuery = decodeURIComponent(filtersQuery);

    let filters = decodedFiltersQuery.split('&')
      .filter(filter => !!filter)
      .map(filter => filter.split('='))
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value.includes(',') ? value.split(',') : value }), {});

    return filters;
  }

  const { data, isLoading, isError } = useGetRoomDataQuery({ slug, userFilters: filters });

  let room = {};
  let items = [];
  let enablePagination = false;
  let pageSize = 20;

  if (data?.roomEntry.data) {
    room = data.roomEntry.data;
    enablePagination = room.enablePagination;
    pageSize = room.pageSize;

    // take different content types from data and transform them into an array of objects
    const { projects, lessons, challenges, activities, resources } = room;

    if (projects) {
      items = [...items, ...projects.data];
    }

    if (lessons) {
      items = [...items, ...lessons.data];
    }

    if (challenges) {
      items = [...items, ...challenges.data];
    }

    if (activities) {
      items = [...items, ...activities.data];
    }

    if (resources) {
      items = [...items, ...resources.data];
    }

    items.sort((a, b) => {
      // sort by ordering or updated at
      return b.attributes.ordering - a.attributes.ordering || new Date(b.attributes.updatedAt) - new Date(a.attributes.updatedAt);
    });
  }

  useEffect(() => {
    const parsedFilters = parseFilters(filtersQuery);
    setFiltersState(parsedFilters)
  }, [queryFilteringIsInitialized]);

  useEffect(() => {
    setPage("library");
  });

  if (isLoading) {
    return <Loader />;
  }

  let itemsToRender = items;
  let hasNextPage = false;

  if (enablePagination) {
    itemsToRender = items.slice(0, parseInt(visiblePagesAmount, 10) * pageSize);
    hasNextPage = items.length > parseInt(visiblePagesAmount, 10) * pageSize;
  }

  return room.enabled ? (
    <>
      <Grid container spacing={2} direction="row" justifyContent="start" alignItems="flex-start" sx={{ mb: 4 }}>
        {room.showHeading && (
          <Grid item xs={12}>
            <Typography variant={"h4"}>{room.name}</Typography>
          </Grid>
        )}

        {room.description && (
          <Grid item xs={12}>
            <RichText data={room.description} />
          </Grid>
        )}

        <Grid item xs={12}>
          <Filters room={slug} setFilters={setFilters} clearFilters={clearFilters} filters={filters} />
        </Grid>

        {isLoading && (
          <Grid item xs={12}>
            <Loader />
          </Grid>
        )}

        {isError && (
          <Grid item xs={12}>
            Error happened
          </Grid>
        )}
      </Grid>

      <Grid container spacing={4} direction="row" justifyContent="start" alignItems="stretch">
        {!itemsToRender.length && (
          <Grid item xs={12} container justifyContent={'center'}>
            <Typography variant={'x-body-bold'} sx={{ mt: 2 }} color={'grey.700'}>
              There is no content matching your filter
            </Typography>
          </Grid>
        )}
        {itemsToRender.map(item => {
          const transformedItem = transformEntity(item);

          return (
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3} key={`${item.__typename}-${item.id}`}>
              <AutomaticCard key={item.id} item={transformedItem} />
            </Grid>
          );
        })}

        {enablePagination && hasNextPage && (
          <Grid item xs={12} container justifyContent={'center'}>
            <Button
              variant={'outlined'}
              size={'large'}
              onClick={() => setVisiblePagesAmount(parseInt(visiblePagesAmount, 10) + 1 + "")}>Load more</Button>
          </Grid>
        )}
      </Grid>
    </>
  ) : (
    <>This room is disabled</>
  );
};
export default Room;
