import React, { useEffect } from 'react'
import { useNav } from '@lib/hooks/index'

const Help = ({ ...props }) => {
  const { setPage } = useNav()

  useEffect(() => {
    setPage('help')
  })

  return <>Help</>
}

export default Help
