import React, { useEffect } from 'react'
import { useNav } from '@lib/hooks/index'

const Competitions = ({ ...props }) => {
  const { setPage } = useNav()

  useEffect(() => {
    setPage('competitions')
  })

  return <>Competitions</>
}

export default Competitions
