import React, { useState } from 'react'

import { navigate } from 'gatsby'
import ScrollToTop from 'react-scroll-to-top'
import { Box } from '@mui/material'

import { colors as themeColors } from '@themes/config/theme-colors'

import { Logo } from '@components/Logo/Logo'
import { MainMenu } from '@components/Menu/MainMenu'

import { TopBar } from './partials/top-bar'

const SteamhubLayout = ({ children }) => {
  const [isMainMenuOpened, setIsMainMenuOpened] = useState(false)

  let sidebarWidth = isMainMenuOpened ? 394 : 110
  let headerHeight = 110
  let footerHeight = 50

  return (
    <>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: `110px 1fr`,
          gridTemplateRows: `${headerHeight}px 1fr ${footerHeight}px`,
          gap: 0,
          gridTemplateAreas: `
                    "sidebar header"
                    "sidebar content"
                    "sidebar footer"
                    `,
          height: '100vh',
          transition: '0.2s',
          zIndex: 10,
        }}
      >
        <Box
          sx={{
            gridArea: 'sidebar',
            position: 'fixed',
            height: '100vh',
            width: `${sidebarWidth}px`,
            transition: 'width 0.2s',
            backgroundColor: themeColors.white.main,
            zIndex: 20,
          }}
          onMouseEnter={() => setIsMainMenuOpened(true)}
          onMouseLeave={() => setIsMainMenuOpened(false)}
        >
          <Box
            sx={{
              display: 'flex',
              flexGrow: 1,
              alignItems: 'center',
              justifyContent: 'left',
              height: headerHeight,
              borderBottom: `1px solid ${themeColors.black['100']}`,
              paddingLeft: '40px',
            }}
          >
            <Logo variant="main" width="30px" height="30px" onClick={() => navigate('/')} />
          </Box>

          <MainMenu
            open={isMainMenuOpened}
            closeHandler={() => setIsMainMenuOpened(false)}
            sx={{
              borderRight: `1px solid ${themeColors.black['100']}`,
            }}
          />
        </Box>

        <Box
          sx={{
            gridArea: 'header', // todo Uncomment position: 'sticky',
            top: 0,
            zIndex: 1,
            backgroundColor: '#F5F5F5',
          }}
        >
          <TopBar />
        </Box>

        <Box
          sx={{
            gridArea: 'content',
            backgroundColor: '#F5F5F5',

            zIndex: 4,
            padding: '32px 64px 0 64px',
          }}
        >
          {children}
        </Box>

        <Box
          sx={{
            gridArea: 'footer',
            backgroundColor: '#F5F5F5',
          }}
        >
          {/*Footer*/}
        </Box>
      </Box>

      <ScrollToTop smooth style={{ zIndex: 9999, right: '8px', bottom: '16px' }} />
    </>
  )
}

export default SteamhubLayout
