import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'

const PaperContent = props => {
  const { children, ...rest } = props

  return (
    <Grid item xs={12} {...rest}>
      {children}
    </Grid>
  )
}

PaperContent.propTypes = {
  children: PropTypes.node,
}

export default PaperContent
