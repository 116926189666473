import React from 'react'
import SimpleDialog, { propTypes } from '@components/Dialog/simple-dialog'
import { Typography, Grid } from '@mui/material'

const SyncWithLMSDialog = props => {
  return <SimpleDialog submitButtonTitle={'Connect'} {...props}>
    <Grid container spacing={2}>
      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <Typography variant="h3">Connecting to LMS...</Typography>
      </Grid>

      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <Typography variant="body1">
          Redirecting to your LMS
        </Typography>
      </Grid>
    </Grid>
  </SimpleDialog>
}

SyncWithLMSDialog.propTypes = propTypes

export default SyncWithLMSDialog
