import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@mui/material'

import { Image as MuiImage } from 'mui-image'
import { calculateImageUrl } from "@lib/utils/urls"

const Image = props => {
  const { caption, file, stretched, withBackground, withBorder } = props
console.log('FILE', file)
  const imageUrl = calculateImageUrl(file)

  return (
    <Box sx={{ mt: 1, mb: 2 }}>
      <MuiImage src={imageUrl} alt={file.alt} showLoading={true} style={{borderRadius: '20px'}} />
    </Box>
  )
}

Image.propTypes = {
  caption: PropTypes.string,
  file: PropTypes.object,
  stretched: PropTypes.bool,
  withBackground: PropTypes.bool,
  withBorder: PropTypes.bool,
}

Image.defaultProps = {
  caption: '',
  stretched: false,
  withBackground: false,
  withBorder: false,
}

export default Image
