import React from "react";
import PropTypes from "prop-types";
import { MenuItem } from "@mui/material";

import BasicFilter from "./helpers/basic-filter";

const SharedFilter = props => {
  const { filterOptions, filters, setFilters } = props;
  const { title, multiple, showSharedWithMe, showSharedByMe } = filterOptions;

  return (
    <BasicFilter name={"shared"} multiple={multiple} setFilters={setFilters} filters={filters}>
      <MenuItem value={0}>{title}</MenuItem>

      {showSharedByMe && <MenuItem value="shared-by-me">Shared By Me</MenuItem>}
      {showSharedWithMe && <MenuItem value="shared-with-me">Shared With Me</MenuItem>}

    </BasicFilter>
  );
};

SharedFilter.propTypes = {
  filterOptions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    multiple: PropTypes.bool.isRequired,

    showSharedByMe: PropTypes.bool,
    showSharedWithMe: PropTypes.bool
  }),

  setFilters: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired
};

SharedFilter.defaultProps = {
  filterOptions: {
    showSharedByMe: false,
    showSharedWithMe: false
  }
};

export default SharedFilter;
